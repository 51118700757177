
export default class Utils {
    constructor(zones, zoneDetails) {
        this.zones = zones;
        this.zoneDetails = zoneDetails;
    }

    getDeepLocation(province, zone) {
        let detail = '';

        let renderLines = [];

        this.zoneDetails.forEach(i => {
            if (i[1] === province) {
                let lines = i[2][parseInt(zone)-1];
                lines.forEach(line => {
                    if (parseInt(line.charAt(0))) {
                        renderLines.push(line);
                    } else {
                        if (renderLines.length == 0) {
                            renderLines[0] = '';
                        }
                        renderLines[renderLines.length-1] += ' ' + line
                    }
                });
                detail = renderLines.join(' — ')
                return false;
            }
        });

        if (!detail) {
            let items = [];
            this.zones.forEach(level1 => {
                if (level1.name === province) {
                    level1.children.forEach(level2 => {
                        level2.children.forEach(level3 => {
                            if (parseInt(level3.zone) === parseInt(zone)) {
                                items.push(level3.name);
                            }
                        });
                    });

                    return false;
                }
            });
            detail = items.join(' ');
        }

        return detail;
    }
}