import React from 'react';
const wordcut = require('../../wordcut');

const PARSE_WAITING = 1;
const PARSE_SUCCESS = 2;
const PARSE_FAILURE = 3;

const STATE_IDLE = 1;
const STATE_LISTENING = 2;
const STATE_WAIT_FOR_CONFIRM = 3;

const Speech = (props) => {

  const [regcognition, setRegcognition] = React.useState(null);
  const [lastSpeech, setLastSpeech] = React.useState('');
  const [parseState, setParseState] = React.useState(PARSE_WAITING);
  const [scores, setScores] = React.useState([]);
  const [state, setState] = React.useState(STATE_IDLE);


  React.useEffect(() => {
    let reg = null;
    if ('webkitSpeechRecognition' in window) {
      reg = new webkitSpeechRecognition();
      reg.continuous = false;
      reg.lang = 'th_TH';
      reg.maxAlternatives = 1;

      reg.onresult = (event) => {
        const last = event.results.length - 1;
        const text = event.results[last][0].transcript;
        setLastSpeech(text);
        try {
          console.log('try to parse', text);
          const scores = wordcut.parse(text);
          setParseState(PARSE_SUCCESS);
          setScores(scores);
        } catch (e) {
          console.log('error', e);
          setParseState(PARSE_FAILURE);
        }
        setState(STATE_WAIT_FOR_CONFIRM)
      };

      reg.onspeechend = (event) => {
        reg.stop();
      };

      reg.onerror = (event) => {
        console.log('error with speech');
        setLastSpeech('???');
        setParseState(PARSE_FAILURE);
        setState(STATE_WAIT_FOR_CONFIRM);
      };

      reg.onend = (event) => {
        console.log('on end', event);
      };

      setRegcognition(reg);
    };

    return () => {
      if (reg) {
        reg.stop();
      }
    }
  }, []);

  const startListening = () => {
    setParseState(PARSE_WAITING);
    setState(STATE_LISTENING);
    regcognition.start();
  };

  const confirm = () => {
    if (props.onResult) {
      props.onResult(scores);
    }
    setState(STATE_IDLE);
  };

  let display = null;
  switch (state) {
    case (STATE_IDLE):
      display = <div className="speech idle p-2 d-flex flex-row justify-content-between align-items-center">
        <div className="">
          <div className="_fw-med _fs-24 text-white">รายงานคะแนนด้วยการอ่านออกเสียง</div>
          <div className="_fw-reg _fs-20 text-white"><em>ตัวอย่าง เช่น เบอร์หนึ่งได้สองร้อยสามสิบคะแนน</em></div>
        </div>
        <button className="btn btn-speech rounded-circle" onClick={startListening} style={{ width: '42px', height: '42px', backgroundColor: 'red', color: 'white' }}><img src="/static/images/icon-speech/ic-microphone.png" srcSet="/static/images/icon-speech/ic-microphone.png 1x, /static/images/icon-speech/ic-microphone@2x.png 2x"/> <span className="sr-only">Start</span></button>
      </div>
      break;
    case (STATE_LISTENING):
      display = <div className="speech loading p-2 d-flex flex-row justify-content-center align-items-center text-center"><img src="/static/images/icon-speech/ic-loading.png" srcSet="/static/images/icon-speech/ic-loading.png 1x, /static/images/icon-speech/ic-loading@2x.png 2x"/><span className="sr-only">... listening ...</span></div>;
      break
    case (STATE_WAIT_FOR_CONFIRM):
      display = <div className={`speech record p-2 d-flex flex-row justify-content-center align-items-center text-center ${parseState != PARSE_SUCCESS ? 'danger' : 'success' }`}>
        <div className={`_fw-med _fs-24 text-white text-left`}>{lastSpeech}</div>
        
        {parseState === PARSE_SUCCESS 
          ?  
          <button className="btn btn-speech rounded-circle border-0 p-0 confirm" onClick={confirm}><img src="/static/images/icon-speech/ic-confirm.png" srcSet="/static/images/icon-speech/ic-confirm.png 1x, /static/images/icon-speech/ic-confirm@2x.png 2x"/> <span className="sr-only">Confirm</span></button> 
          : 
          <div className="btn btn-speech rounded-circle border-0 p-0 d-flex align-items-center justify-content-center"><i className="fa fa-exclamation-circle _fs-28"></i> <span className="sr-only">error icon</span></div>
        }

        <button className="btn btn-speech rounded-circle border-0 p-0 reload" onClick={startListening}><img src="/static/images/icon-speech/ic-reload.png" srcSet="/static/images/icon-speech/ic-reload.png 1x, /static/images/icon-speech/ic-reload@2x.png 2x"/> <span className="sr-only">อ่านใหม่</span></button>
      </div>
      break;
  }

  return (
    <div className="speech-wrapper">
      {display}
    </div>
  )
};

export default Speech;