import React from "react";

import {
    LineShareButton, LineIcon,
    FacebookShareButton, FacebookIcon,
    TwitterShareButton, TwitterIcon,
    EmailShareButton, EmailIcon
} from "react-share";

import {Link} from "react-router-dom";

export default class VisualSidebar extends React.Component {

    constructor(props) {
        super(props);

        this.hub = props.hub;
    }


    render() {
        return (
            <div className="visual-sidebar py-5 px-sm-4 px-3 _fw-reg">
                <h2 className="text-center _fs-40">ร่วมเป็นอาสาสมัคร</h2>

                <p className="text-center _fs-28">ตรวจสอบผลการเลือกตั้งซ่อม <br />สมุทรปราการ เขตเลือกตั้งที่ 5</p>

                <Link to="/crowdsource/upload" className="text-black d-block border rounded text-center p-4 h-100 _fs-22 mb-4 shadow">
                    <div>กรอกคะแนนอัปโหลดรูปภาพการนับคะแนนจากหน้าหน่วยเลือกตั้ง</div>
                    <img src="/static/images/ic-report-orange.png" srcSet="/static/images/ic-report-orange.png 1x, /static/images/ic-report-orange@2x.png 2x" className="img-fluid my-3" alt="อัปโหลดภาพ" />
                    <div className="_fs-24">อัปโหลดภาพ</div>
                </Link>
                
                <Link to="/crowdsource/digitize" className="text-black d-block border rounded text-center p-4 h-100 _fs-22 mb-4 shadow">
                    <div>กรอกคะแนนจากรูปภาพกระดานนับคะแนนเลือกตั้ง</div>
                    <img src="/static/images/ic-send-result-orange.png" srcSet="/static/images/ic-send-result-orange.png 1x, /static/images/ic-send-result-orange@2x.png 2x" className="img-fluid my-3" alt="กรอกคะแนน" />
                    <div className="_fs-24">กรอกคะแนน</div>
                </Link>

                {/* <div className="mb-4">
                    <div>รูปภาพรอการกรอกคะแนน</div>
                    <div className="count _fs-24"><span className="_fs-42 _fw-bd">345</span> <span>รูป</span></div>
                </div>

                <div className="mb-4">
                    <div>อัปโหลดรูปภาพแล้ว</div>
                    <div className="count _fs-24"><span className="_fs-42 _fw-bd">58</span> / <span>158</span> <span>หน่วย</span></div>
                </div>
                 */}
                <p className="text-center _fs-24 _fw-reg">ส่งต่อ</p>

                {/* <div className="contact-link d-flex justify-content-center mb-3">
                    <a className="mx-2" href="line.me"><img src="/static/images/icon-share/ic-line.png" srcSet="/static/images/icon-share/ic-line.png 1x, /static/images/icon-share/ic-line@2x.png 2x" /><span className="sr-only">Line</span></a>
                    <a className="mx-2" href="facebook.com"><img src="/static/images/icon-share/ic-facebook.png" srcSet="/static/images/icon-share/ic-facebook.png 1x, /static/images/icon-share/ic-facebook@2x.png 2x" /><span className="sr-only">Facebook</span></a>
                    <a className="mx-2" href="twitter.com"><img src="/static/images/icon-share/ic-twitter.png" srcSet="/static/images/icon-share/ic-twitter.png 1x, /static/images/icon-share/ic-twitter@2x.png 2x" /><span className="sr-only">Twitter</span></a>
                    <a className="mx-2" href="mailto:info@vote62.com"><img src="/static/images/icon-share/ic-envelope.png" srcSet="/static/images/icon-share/ic-envelope.png 1x, /static/images/icon-share/ic-envelope@2x.png 2x" /><span className="sr-only">email</span></a>
                </div> */}

                <div className="share-buttons text-center">
                    <span>
                        <LineShareButton url={window.location.href}><LineIcon/></LineShareButton>
                    </span>
                    <span>
                        <FacebookShareButton url={window.location.href}><FacebookIcon/></FacebookShareButton>
                    </span>
                    <span>
                        <TwitterShareButton url={window.location.href}><TwitterIcon/></TwitterShareButton>
                    </span>
                    <span>
                        <EmailShareButton url={window.location.href}><EmailIcon/></EmailShareButton>
                    </span>
                </div>

                <br/>
                <h4>การเลือกตั้งที่ผ่านมา</h4>
                <ul>
                    <li>
                        <a href="https://l4.vote62.com/">
                            เลือกตั้งซ่อมลำปางเขต 4
                        </a>
                    </li>
                    <li>
                        <a href="https://k4.vote62.com/">
                            เลือกตั้งซ่อมกำแพงเพชรเขต 4
                        </a>
                    </li>
                    <li>
                        <a href="https://k7.vote62.com/">
                            เลือกตั้งซ่อมขอนแก่นเขต 7
                        </a>
                    </li>
                    <li>
                        <a href="https://election.vote62.com/">
                            เลือกตั้งทั่วไป 24 มีนาคม
                        </a>
                    </li>
                </ul>
            </div>
        )
    }

}