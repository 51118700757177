import React from "react";

import PageComponent from "./PageComponent.jsx";
import NewsBlock from "./NewsBlock.jsx";

export default class News extends PageComponent {

    constructor(props) {
        super(props);

        this.pageCode = 'news';
        this.hub = props.hub;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.hub.pushPath(this.pageCode);
        this.hub.logStat(this.pageCode, 'news-page');
    }

    render() {
        return (
            <div className="container">
                <NewsBlock/>
            </div>
        )
    }
}