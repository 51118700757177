import React from "react";

import ReactTooltip from 'react-tooltip'
import {Link} from "react-router-dom";

import Party from "../data/party.json";

export default class VisualUnitResult extends React.Component {

    constructor(props) {
        super(props);

        this.hub = props.hub;
        this.zone = '5'
        this.state = {
            modal: null
        }

    }

    unitItem(unit, result) {

        let hasResult = result && result[unit.name]
        if (false) {
            return (
                <div key={unit.id} className="monitoring-status empty">
                    <div className="unit-result rounded-circle d-flex flex-row align-items-center justify-content-center" style={{ backgroundColor: "transparent" }}>
                        <div className="rounded-circle" style={{ backgroundColor: "white" }}>&nbsp;</div>
                    </div>
                </div>
            )
        } else if (!hasResult) {
            return (
                <Link key={unit.id} to={`/crowdsource/upload?location=${unit.id}`} className="monitoring-status empty" data-toggle="tooltip" data-placement="top" data-tip={unit.name}>
                    <div className="unit-result rounded-circle d-flex flex-row align-items-center justify-content-center">
                        <div className="rounded-circle">&nbsp;</div>
                    </div>
                </Link>
            )
        } else  {
            return (
                <Link key={unit.id} to={`/visual/search?location=${unit.id}`} className="monitoring-status has-result" data-toggle="tooltip" data-placement="top" data-tip={unit.name}>
                    <div className="unit-result rounded-circle d-flex flex-row align-items-center justify-content-center" style={{ border: "1px solid white" }}>
                        <img className="img-fluid d-block d-none" src="/static/images/ic-check-white.png" srcSet="/static/images/ic-check-white.png 1x, /static/images/ic-check-white@2x.png 2x" />
                    </div>
                </Link>
            )
        }
    }

    unitDetailItem(unit, result, tambon) {
        let hasResult = result && result[unit.name]
        let unitNo = unit.name.split(' - ')[0].replace('หน่วยเลือกตั้งที่ ', '')
        let unitDescription = unit.name.split(' - ')[1]

        let to = hasResult? `/visual/search?location=${unit.id}`: `/crowdsource/upload?location=${unit.id}`

        return (
            <tr key={unit.id}>
                <td>
                    <Link to={to} className={``} onClick={() => { $(`.modal-backdrop`).hide(); $('body').removeClass('modal-open')}}>{unitNo}</Link>
                </td>
                <td>
                    <Link to={to} className={``} onClick={() => { $(`.modal-backdrop`).hide(); $('body').removeClass('modal-open')}}>{unitDescription}</Link>
                </td>
                <td>
                    <Link to={to} className={``} onClick={() => { $(`.modal-backdrop`).hide(); $('body').removeClass('modal-open')}}>
                        <div className={`monitoring-status unit-status ${hasResult? `has-result`: `no-result empty`}`} style={{cursor: 'pointer'}}>
                            {!hasResult ?
                            <div className="unit-result rounded-circle d-flex flex-row align-items-center justify-content-center">
                                {/* <div className="rounded-circle">&nbsp;</div> */}
                                <img className="img-fluid d-block d-none" src="/static/images/ic-report-orange.png" srcSet="/static/images/ic-report-orange.png 1x, /static/images/ic-report-orange@2x.png 2x" width="24px" />
                            </div>
                            : 
                            <div className="unit-result rounded-circle d-flex flex-row align-items-center justify-content-center" style={{ border: "1px solid white" }}>
                                <img className="img-fluid d-block d-none" src="/static/images/ic-check-white.png" srcSet="/static/images/ic-check-white.png 1x, /static/images/ic-check-white@2x.png 2x" />
                            </div>
                            }
                        </div>
                    </Link>
                </td>
            </tr>
        )
    
    }

    monitoringStatusItem(amphoe) {

        let unitResult = this.props.unitResult
        let result = null

        if (unitResult) {
            result = unitResult[amphoe]
        }

        console.log('this.hub.zones', this.hub.zones)
        let tanbonMap = this.hub.zones[0][this.zone][amphoe]
        let tanbons = tanbonMap.children

        let totalUnits = 0
        tanbons.forEach(t => t.children.forEach(u => totalUnits++))

        let totalCompleteUnits = 0
        if (result) {
            console.log(amphoe, result)
            Object.keys(result).filter(key => key).forEach(key=>{
                totalCompleteUnits += Object.keys(result[key]).filter(key2 => key2).length
            });
        }

        return (
            <div className="item mb-3">

                <div>
                    {tanbons.map(tambon => (
                        <div key={tambon.id}>
                            <div className="d-flex flex-row align-items-center justify-content-start">
                                <h4 className="_fs-20 text-white mb-0">{tambon.name}</h4>
                                <button className="btn btn-link _fs-20 _fw-reg rounded-circle text-white mr-2" data-toggle="modal" data-target={`#result-${tambon.id}-modal`}><i className="fa fa-search-plus"></i></button>

                            </div>
                            <div className="monitoring-items d-flex flex-row justify-content-start aling-items-center flex-wrap mb-3">
                                {tambon.children.map(unit => this.unitItem(unit, result && result[tambon.name]))}
                            </div>


                            <div className="modal fade" id={`result-${tambon.id}-modal`} aria-labelledby="" tabIndex="-1" role="dialog" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">{amphoe} » {tambon.name}</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>

                                        <div className={`modal-body`}>
                                            <table className="table table-striped">
                                                <tbody>
                                                    <tr>
                                                        <th>หน่วย</th>
                                                        <th>สถานที่</th>
                                                        <th></th>
                                                    </tr>
                                                    {tambon.children.map(unit => this.unitDetailItem(unit, result && result[tambon.name], tambon))}
                                                
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                


                <div className="monitoring-info text-white">
                    <ul className="list-unstyled">
                        <li className="_fs-18 _fw-reg">
                            <div className="monitoring-status d-inline-block mr-2 has-result">
                                <div className="unit-result rounded-circle d-flex flex-row align-items-center justify-content-center" style={{ border: "1px solid white" }}>
                                    <img className="img-fluid d-block d-none" src="/static/images/ic-check-white.png" srcSet="/static/images/ic-check-white.png 1x, /static/images/ic-check-white@2x.png 2x" width="8px" />
                                </div>
                            </div>
                            <div>กรอกคะแนนแล้ว</div>
                            <div className="count"><span className="_fs-32 _fw-bd">{totalCompleteUnits}</span> / <span>{totalUnits}</span> <span>หน่วย</span></div>
                        </li>
                        <li className="_fs-18 _fw-reg">
                            <div className="monitoring-status d-inline-block mr-2 empty">
                                <div className="unit-result rounded-circle d-flex flex-row align-items-center justify-content-center" style={{ backgroundColor: "transparent" }}>
                                    <div className="rounded-circle">&nbsp;</div>
                                </div>
                            </div>
                            <div>หน่วยที่ยังไม่มีรูปจากหน้าหน่วย</div>
                            <div className="count"><span className="_fs-32 _fw-bd" style={{ color: '#A34B67' }}>{totalUnits-totalCompleteUnits}</span> / <span>{totalUnits}</span> <span>หน่วย</span></div>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

    render() {

        return (
            <div className="py-4 bg-light-grey">
                <div className="container">
                    <div className="virtual-unit-result">

                        <div className="result-item left">
                            <div className="mb-3 text-left text-white">
                                <div className="_fs-16 _fw-med">อำเภอ</div>
                                <div className="_fs-30 _lh-80 _fw-bd">บางบ่อ</div>
                            </div>
                            { this.monitoringStatusItem('อ.บางบ่อ') }
                        </div>
                        <div className="result-item right ">
                            <div className="mb-3 text-left text-white">
                                <div className="_fs-16 _fw-med">อำเภอ</div>
                                <div className="_fs-30 _lh-80 _fw-bd">บางเสาธง</div>
                            </div>
                            { this.monitoringStatusItem('อ.บางเสาธง') }
                        </div>
                        <div className="result-item right last">
                            <div className="mb-3 text-left text-white">
                                <div className="_fs-16 _fw-med">อำเภอ</div>
                                <div className="_fs-30 _lh-80 _fw-bd">บางพลี</div>
                            </div>
                            { this.monitoringStatusItem('อ.บางพลี') }
                        </div>



                    </div>
                </div>
                <ReactTooltip />
            </div>
        )
    }

}