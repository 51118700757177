import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

import ReactGA from 'react-ga';

import React, {Component} from "react";
import ReactDOM from "@hot-loader/react-dom";
// import { AppContainer } from 'react-hot-loader'

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Papa from "papaparse";

import Header from "./Header.jsx";
import Overview from "./Overview.jsx";
import Visual from "./Visual.jsx";
import News from "./News.jsx";
import NoMatch from "./NoMatch.jsx";
import Utils from "../../Utils.jsx";
import CrowdSourcingVoteSubmit from './CrowdSourcingVoteSubmit.jsx'
import CrowdSourcingThankYou from './CrowdSourcingThankYou.jsx'
import CrowdSourcing from './CrowdSourcing.jsx'
import CrowdSourcingRandom from './CrowdSourcingRandom.jsx'
import CrowdSourcingOverview from './CrowdSourcingOverview.jsx'
import AdminCrowdSourcing from './admin/AdminCrowdSourcing.jsx'
// import CrowdSourcingSearch from "./CrowdSourcingSearch.jsx";
// import CrowdSourcingProvinceDetail from "./CrowdSourcingProvinceDetail.jsx";
import CrowdSourcingZoneDetail from "./CrowdSourcingZoneDetail.jsx";

import * as axios from 'axios'
import Redirect from 'react-router-dom/es/Redirect'
import VisualSearchProgress from './VisualSearchProgress.jsx';

// hot reload
// import { hot } from 'react-hot-loader/root'

// Prevent log in production
let appMode = 'development';
let isDevSite = (window.location.hostname.indexOf('dev.') === 0)

if (typeof(PRODUCTION) !== "undefined" && !isDevSite) {
    appMode = 'production';
}
// appMode = 'production';


if (appMode !== 'development') {
    // inject raven
    let _log = window.console.log
    // eslint-disable-next-line no-global-assign
    window.console.log = (...args) => {
        _log.call(window.console, ...args)

        args.forEach(item => {
            if (item instanceof Error) {
                if (window.Raven) {
                    Raven.captureException(item)
                }
            }
        })
    }

    Raven
        .config('https://8e408f0d66d747e9943585ec03b612a2@sentry.opendream.co.th/8', {
            logger: 'javascript',
            environment: appMode
        })
        .install()
}

// Safe old browser
String.prototype.endsWith = function(suffix) {
    return this.indexOf(suffix, this.length - suffix.length) !== -1;
};

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

class App extends Component {

    constructor(props) {
        super(props);

        this.appWrapper = null

        // Initialize Firebase
        const config = {
            apiKey: "AIzaSyBeQq-rlRqyV8Xbx0UgcVbz5CkCfwkzZ1Q",
            authDomain: "thai-vote-samut-prakan-62.firebaseapp.com",
            databaseURL: "https://thai-vote-samut-prakan-62.firebaseio.com",
            projectId: "thai-vote-samut-prakan-62",
            storageBucket: "thai-vote-samut-prakan-62.appspot.com",
            messagingSenderId: "788913275837",
            useEmulator: appMode === 'development' && !isDevSite,
            emulatorFunctionsUrl: 'http://localhost:5000'
        };

        if (!firebase.apps.length) {
            firebase.initializeApp(config);
        }

        let db = firebase.firestore();
        let functions = firebase.functions()

        let referrerUrl = decodeURIComponent(document.referrer || '');
        let referrerDomain = '';
        if (referrerUrl) {
            referrerDomain = referrerUrl.split('://')[1].split('/')[0].split(':')[0];
            if (window.location.hostname === referrerDomain) {
                referrerUrl = '';
                referrerDomain = '';
            }
        }

        if (appMode === 'development' && !isDevSite) {
            functions.useFunctionsEmulator('http://localhost:5001')
        }

        // Change this date
        const startInputDateTime = new Date(2020, 8-1, 9, 17, 0)
        const isInputOpen = (new Date() >= startInputDateTime) || (appMode === 'development')
        const setting = {
            startInputDateTime: startInputDateTime,
            isInputOpen: isInputOpen
        }

        this.hub = {
            config: config,
            db: db,
            functions: functions,
            appMode: appMode,
            setting: setting
        };

        // Paths for analytic
        let paths = [];
        let totalPaths = 0;
        this.hub.pushPath = (pageCode) => {
            const lastestPath = paths[paths.length - 1];
            if (typeof (lastestPath) == 'undefined' || pageCode !== lastestPath) {
                paths.push(pageCode);
                totalPaths++;
                paths = paths.slice(paths.length - 10, paths.length);
            }
        };
        // Initialize Data
        let userId = window.localStorage.getItem('userId');
        if (!userId) {
            userId = uuidv4();
            try {
                window.localStorage.setItem('userId', userId);
            } catch (e) {
                // Safe old ios device in safari private mode
                window.localStorage.setItem = (key, value) => null;
            }
        }

        this.hub.userId = userId;
        this.hub.ageRange = window.localStorage.getItem('ageRange') || '';

        // Terminate parties
        this.hub.terminates = ['พรรคไทยรักษาชาติ'];
        // Analytic

        const ua = navigator.userAgent.toLowerCase()
        this.hub.android = ua.indexOf('android') > -1;
        this.hub.iOS = /ipad|iphone|ipod/.test(ua) && !window.MSStream;

        let device = 'desktop';
        if (this.hub.iOS) {
            device = 'ios';
        } else if (this.hub.android) {
            device = 'android';
        }

        ReactGA.initialize('UA-135694244-1');
        this.hub.logStat = (source, key, value, callback) => {

            key = appMode + '--' + key;

            let data = {
                meta: {
                    source: source,
                    referrerUrl: referrerUrl,
                    referrerDomain: referrerDomain,
                    userId: this.hub.userId,
                    ageRange: this.hub.ageRange,
                    paths: paths,
                    totalPaths: totalPaths,
                    createdAt: new Date(),
                    device: device
                }
            };
            if (value) {
                data.value = value;
            }

            if (appMode === 'production') {

                let r = db.collection(key).add(data);
                if (callback) {
                    r.then(() => {
                        callback();
                    });
                }

                console.log(key, JSON.parse(JSON.stringify(data)));


                if (key.endsWith('-page')) {
                    ReactGA.pageview(decodeURIComponent(window.location.pathname));

                } else {
                    ReactGA.event({
                        category: source,
                        action: key
                    });
                }
            } else {
                console.log(key, JSON.parse(JSON.stringify(data)));

                let r = db.collection(key).add(data);
                if (callback) {
                    r.then(() => {
                        callback();
                    });
                }
            }
        }

        this.state = {
            inited: false
        }
    }

    getAppWrapperClass = () => {
        return ('page' + window.location.pathname
            .replace(/\//g, '-'))
            .replace(/-*$/g, '')
    }

    componentDidMount = () => {
        this.initData()
        window.addEventListener('popstate', () => {
            if (this.appWrapper) {
                this.appWrapper.className = this.getAppWrapperClass()
            }
        }, false)
    }

    initData = async () => {

        let zonePromise = axios.get(`https://firebasestorage.googleapis.com/v0/b/thai-vote-samut-prakan-62.appspot.com/o/data%2Fsamut-prakan-62.zone.csv?alt=media`)
        let candidatePromise = axios.get(`https://firebasestorage.googleapis.com/v0/b/thai-vote-samut-prakan-62.appspot.com/o/data%2Fsamut-prakan-62.candidate.csv?alt=media`)

        try {
            let [
                zoneResp,
                candidateResp,
            ] = await Promise.all([zonePromise, candidatePromise])



            let zones = Papa.parse(zoneResp.data, {header: true}).data

            let treeZones = []
            let mapZones = {}

            zones.forEach(zone => {

                if (!mapZones[zone['จังหวัด']]) {
                    mapZones[zone['จังหวัด']] = {
                        id: `${zone['จังหวัด']}`,
                        name: zone['จังหวัด'],
                        children: []
                    }
                    treeZones.push(mapZones[zone['จังหวัด']])
                }

                if (!mapZones[zone['จังหวัด']][zone['เขต']]) {
                    mapZones[zone['จังหวัด']][zone['เขต']] = {
                        id: `${zone['จังหวัด']}--${zone['เขต']}`,
                        name: zone['เขต'],
                        children: []
                    }
                    mapZones[zone['จังหวัด']].children.push(mapZones[zone['จังหวัด']][zone['เขต']])
                }

                if (!mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']]) {
                    mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']] = {
                        id: `${zone['จังหวัด']}--${zone['เขต']}--${zone['อำเภอ']}`,
                        name: zone['อำเภอ'],
                        children: []
                    }
                    mapZones[zone['จังหวัด']][zone['เขต']].children.push(mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']])
                }

                if (!mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']][zone['เทศบาล/ตำบล']]) {
                    mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']][zone['เทศบาล/ตำบล']] = {
                        id: `${zone['จังหวัด']}--${zone['เขต']}--${zone['อำเภอ']}--${zone['เทศบาล/ตำบล']}`,
                        name: zone['เทศบาล/ตำบล'],
                        children: []
                    }
                    mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']].children.push(mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']][zone['เทศบาล/ตำบล']])

                }

                let station = `${[zone['ชื่อหน่วยเลือกตั้ง']]}`
                if (!mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']][zone['เทศบาล/ตำบล']][station]) {
                    mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']][zone['เทศบาล/ตำบล']][station] = {
                        id: `${zone['จังหวัด']}--${zone['เขต']}--${zone['อำเภอ']}--${zone['เทศบาล/ตำบล']}--${station}`,
                        name: station
                    }
                    mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']][zone['เทศบาล/ตำบล']].children.push(mapZones[zone['จังหวัด']][zone['เขต']][zone['อำเภอ']][zone['เทศบาล/ตำบล']][station])

                }
            })

            this.hub.zones = treeZones
            this.hub.zoneDetails = []
            this.hub.candidates = Papa.parse(candidateResp.data, {header: true}).data
            this.hub.candidates.forEach(item => item.party = 'พรรค' + item.party)

            // Util for all components
            this.hub.utils = new Utils(this.hub.zones, this.hub.zoneDetails);

            this.setState({inited: true})

        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const inited = this.state.inited

        const setWrapper = ref => {
            if (!ref) {
                return
            }
            this.appWrapper = ref
            ref.className = this.getAppWrapperClass()
        }

        let submitKey = window.location.pathname;
        // let uploadKey = window.location.pathname + '?' +window.location.search;
        if (submitKey.indexOf('from') !== -1) {
            // uploadKey = 'crowdsource-vote-submit';

        }

        const app = (
            <Router>
                <div ref={setWrapper}>
                    <Header/>
                    
                    <Switch>
                        <Route exact path="/usages" component={props => <Overview {...props} hub={this.hub} />} />

                        <Route exact path="/" render={() => <Redirect to="/visual" />} />

                        <Route exact path="/visual" render={props => <Visual {...props} hub={this.hub} />} />
                        <Route exact path="/visual/search" render={props => <VisualSearchProgress {...props} hub={this.hub} />} />
                        <Route exact path="/visual/:source" render={props => <Visual {...props} hub={this.hub} />} />

                        <Route exact path="/news" component={props => <News {...props} hub={this.hub} />} />

                        <Route exact path="/crowdsource" render={props => <CrowdSourcing {...props} hub={this.hub} />} />
                        <Route exact path="/crowdsource/overview" render={props => <CrowdSourcingOverview {...props} hub={this.hub} />} />
                        <Route exact path="/crowdsource/digitize" render={props => <CrowdSourcingRandom {...props} hub={this.hub} />} />
                        <Route exact path="/crowdsource/upload" render={props => <CrowdSourcingVoteSubmit key={'upload-path'} {...props} hub={this.hub}/>} />
                        <Route exact path="/crowdsource/digitize/:id" render={props => <CrowdSourcingVoteSubmit key={window.location.search.indexOf('from') === -1 ? 'upload-path': window.location.pathname} {...props} hub={this.hub} page={2}/>} />
                        <Route exact path="/crowdsource/thank-you" render={props => <CrowdSourcingThankYou {...props} />} />
                        <Route exact path="/crowdsource/search" render={props => <CrowdSourcingZoneDetail key={window.location.pathname} {...props} hub={this.hub} />} />
                        <Route exact path="/crowdsource/search/:province" render={props => <CrowdSourcingZoneDetail key={window.location.pathname} {...props} hub={this.hub} />} />
                        <Route exact path="/crowdsource/search/:province/:zone" render={props => <CrowdSourcingZoneDetail key={window.location.pathname} {...props} hub={this.hub} />} />
                        <Route exact path="/crowdsource/search/:province/:zone/:location" render={props => <CrowdSourcingZoneDetail key={window.location.pathname} {...props} hub={this.hub} />} />


                        <Route exact path="/admin/crowdsource" render={props => <AdminCrowdSourcing {...props} hub={this.hub} />} />

                        <Route component={NoMatch} />
                    </Switch>

                </div>
            </Router>
        )
        const loader = (
            <div className="report-loading-layer-wrapper text-center" style={{zIndex: '9999'}}>
                <div className="report-loading-layer" style={{width: '600px', height: '400px', backgroundColor: 'rgba(0,0,0,0.4)'}}>
                    <div className="mx-auto rounded p-4" style={{maxWidth: '200px', width: '100%', backgroundColor: 'rgba(255,255,255,0.8)'}}>
                        <img src="/static/images/preloading.gif" alt="loading ..." style={{width: '100%', height: 'auto', maxWidth: '200px'}}/>
                    </div>
                </div>
            </div>
        )

        return (
            <div>
                {inited ? app : loader}
            </div>
        )
    }
}

export default App;

const wrapper = document.getElementById("root");
ReactDOM.render(<App/>, wrapper);

// const render = Component => {
//     ReactDOM.render(
//         <AppContainer>
//             <App />
//         </AppContainer>,
//         wrapper
//     );
// }
//
// render(App)

// hot.accept('./App.jsx', () => {
//     render(<App />, wrapper);
// })

/*
const HotApp = hot(App)

export default HotApp;

const wrapper = document.getElementById("root");
ReactDOM.render(<HotApp/>, wrapper);
 */
