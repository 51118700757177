import React, {Component} from "react";

export default class NewsBlock extends Component {

    componentDidMount() {
        this.scrollToHash(1000)


        $(document).ready(function () {
            var size_li = $(".news-list .news-list-item").length;
            var x = 5;
            $('.news-list .news-list-item:lt('+x+')').show();
            $('#loadmore').click(function () {
                x = (x+5 <= size_li) ? x+5 : size_li;
                $('.news-list .news-list-item:lt('+x+')').show();
                if ( x >= size_li ) {
                    $('#loadmore').hide();
                }
            });
            
        });


        $(document).ready(function () {
            var size_li = $(".news-list1 .news-list-item").length;
            var x = 5;
            $('.news-list1 .news-list-item:lt('+x+')').show();
            $('#loadmore1').click(function () {
                x = (x+5 <= size_li) ? x+5 : size_li;
                $('.news-list1 .news-list-item:lt('+x+')').show();
                if ( x >= size_li ) {
                    $('#loadmore1').hide();
                }
            });
        });
        $(document).ready(function () {
            var size_li = $(".news-list2 .news-list-item").length;
            var x = 5;
            $('.news-list2 .news-list-item:lt('+x+')').show();
            $('#loadmore2').click(function () {
                x = (x+5 <= size_li) ? x+5 : size_li;
                $('.news-list2 .news-list-item:lt('+x+')').show();
                if ( x >= size_li ) {
                    $('#loadmore2').hide();
                }
            });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.scrollToHash(0)
    }

    scrollToHash(delay) {
        if (window.innerWidth < 992) {
            const hash = window.location.hash;
            if (hash) {
                const scrollToElm = this.refs[hash.slice(1)];
                if (scrollToElm) {
                    setTimeout(() => {
                        window.scrollTo(0, scrollToElm.getBoundingClientRect().y);
                    }, delay);
                }
            }
        }
    }

    render() {
        return (
            <div className="news">
                <div className="row">
                    <div className="col-12 col-lg-4 border-lg-right">
                        <h3 id="election-rules" ref="election-rules" className="heading">กฎ กติกา การเลือกตั้ง</h3>
                        <div className="news-list">
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://www.facebook.com/adaybulletin/photos/a.10151952118782347/10156849123387347" target="_blank">
                                            <img async
                                                src="https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/images%2Fcontent%2Fnew02%20(1).jpg?alt=media"
                                                className="img-fluid" alt="เรื่องต้องห้าม!!! อย่าไปทำในคูหาเลือกตั้ง"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://www.facebook.com/adaybulletin/photos/a.10151952118782347/10156849123387347" target="_blank">
                                            <h4 className="title">เรื่องต้องห้าม!!! อย่าไปทำในคูหาเลือกตั้ง</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://ilaw.or.th/node/4973" target="_blank">
                                            <img async
                                                src="https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/images%2Fcontent%2FLung%20Um%20Heep-news.jpg?alt=media"
                                                className="img-fluid" alt="เลือกตั้ง 62: ของ คสช. โดย คสช. เพื่อ คสช."/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://ilaw.or.th/node/4973" target="_blank">
                                            <h4 className="title">เลือกตั้ง 62: ของ คสช. โดย คสช. เพื่อ คสช.</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://ilaw.or.th/node/5070" target="_blank">
                                            <img async
                                                src="https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/images%2Fcontent%2Fmm_0-news.jpg?alt=media"
                                                className="img-fluid"
                                                alt="เลือกตั้ง 62 : กาบัตรครั้งเดียวได้ ส.ส. เขต ส.ส.บัญชีรายชื่อ และนายกฯ"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://ilaw.or.th/node/5070" target="_blank">
                                            <h4 className="title">เลือกตั้ง 62 : กาบัตรครั้งเดียวได้ ส.ส. เขต ส.ส.บัญชีรายชื่อ
                                                และนายกฯ</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://ilaw.or.th/node/4973" target="_blank">
                                            <img async
                                                src="https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/images%2Fcontent%2Fpic4.jpg?alt=media"
                                                className="img-fluid"
                                                alt="เลือกตั้ง 62: ระบบนับที่นั่ง MMA ทำพรรคใหญ่แตกตัว พรรคเล็กเกิดไม่ได้"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://ilaw.or.th/node/4973" target="_blank">
                                            <h4 className="title">เลือกตั้ง 62: ระบบนับที่นั่ง MMA ทำพรรคใหญ่แตกตัว
                                                พรรคเล็กเกิดไม่ได้</h4>
                                        </a>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://ilaw.or.th/node/5123" target="_blank">
                                            <img async
                                                src="https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/images%2Fcontent%2F%E0%B8%A3%E0%B8%B9%E0%B9%89%E0%B8%81%E0%B9%88%E0%B8%AD%E0%B8%99%E0%B8%81%E0%B8%B2%E0%B8%81%E0%B8%A5%E0%B9%80%E0%B8%81%E0%B8%A1%E0%B9%80%E0%B8%A5%E0%B8%B7%E0%B8%AD%E0%B8%81%E0%B8%95%E0%B8%B1%E0%B9%89%E0%B8%8762.jpg?alt=media"
                                                className="img-fluid"
                                                alt="ดาวน์โหลดไฟล์ PDF คู่มือการเลือกตั้ง รู้ก่อนกา กลเกมเลือกตั้ง'62 ได้แล้ววันนี้"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://ilaw.or.th/node/5123" target="_blank">
                                            <h4 className="title">ดาวน์โหลดไฟล์ PDF คู่มือการเลือกตั้ง "รู้ก่อนกา
                                                กลเกมเลือกตั้ง'62"
                                                ได้แล้ววันนี้!</h4>
                                        </a>

                                    </div>
                                </div>
                            </div>
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://themomentum.co/party-list-seats-in-the-house-of-representatives/"
                                        target="_blank">
                                            <img async
                                                src="https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/images%2Fcontent%2F02.jpg?alt=media"
                                                className="img-fluid"
                                                alt="FAQ เลือกตั้ง 62 : ส.ส.บัญชีรายชื่อ 150 คน จะมาจากพรรคไหนเท่าไร เขาคำนวณกันยังไง"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://themomentum.co/party-list-seats-in-the-house-of-representatives/"
                                        target="_blank">
                                            <h4 className="title">FAQ เลือกตั้ง 62 : ส.ส.บัญชีรายชื่อ 150 คน
                                                จะมาจากพรรคไหนเท่าไร
                                                เขาคำนวณกันยังไง</h4>
                                        </a>
                                    </div>
                                </div>
                            
                            </div>   
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://themomentum.co/faq-election-62-what-if-i-love-a-candidate-but-hate-the-party/"
                                        target="_blank">
                                            <img async
                                                src="https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/images%2Fcontent%2F03.jpg?alt=media"
                                                className="img-fluid"
                                                alt="FAQ เลือกตั้ง 62 : ถ้า ส.ส. คนที่เราชอบ ไม่ได้อยู่ในพรรคที่ใช่ เราจะเลือกอะไร?"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://themomentum.co/faq-election-62-what-if-i-love-a-candidate-but-hate-the-party/"
                                        target="_blank">
                                            <h4 className="title">FAQ เลือกตั้ง 62 : ถ้า ส.ส. คนที่เราชอบ ไม่ได้อยู่ในพรรคที่ใช่
                                                เราจะเลือกอะไร?</h4>
                                        </a>
                                    </div>
                                </div>
                            
                            </div>   
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                <div className="col-5">
                                    <a href="https://themomentum.co/faq-election-62-why-250-senatorscan-select-pm/"
                                    target="_blank">
                                        <img async
                                            src="https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/images%2Fcontent%2F01.jpg?alt=media"
                                            className="img-fluid"
                                            alt="FAQ เลือกตั้ง 62: ส.ว. 250 คน ทำไมมีสิทธิโหวตนายกรัฐมนตรี?"/>
                                    </a>
                                </div>
                                <div className="col-7 pl-2 pl-md-3">
                                    <a href="https://themomentum.co/faq-election-62-why-250-senatorscan-select-pm/"
                                    target="_blank">
                                        <h4 className="title">FAQ เลือกตั้ง 62: ส.ว. 250 คน
                                            ทำไมมีสิทธิโหวตนายกรัฐมนตรี?</h4>
                                    </a>
                                </div>
                            </div>
                            </div>
                        
                        </div>    
                        <div id="loadmore" className="text-center">
                            <a className="btn btn-outline">Load More</a>
                        </div>
                        
                        </div>
                    <div className="col-12 col-lg-4 border-lg-right">
                        <h3 id={'politiciants'} ref="politiciants" className="heading">รู้จักนักการเมือง</h3>
                        <div className="news-list1">
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://themomentum.co/anutin-charnvirakul-interview-election-2019/"
                                        target="_blank">
                                            <img async
                                                src="https://themomentum.co/wp-content/uploads/2019/03/TheMO-อนุทินชาญวีรกูล_FBthumbnail.jpg"
                                                className="img-fluid"
                                                alt="อนุทิน ชาญวีรกูล: ชายในมุมเงียบที่อาจเป็นม้ามืดในเกมเลือกตั้ง 62"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://themomentum.co/anutin-charnvirakul-interview-election-2019/"
                                        target="_blank">
                                            <h4 className="title">อนุทิน ชาญวีรกูล: ชายในมุมเงียบที่อาจเป็นม้ามืดในเกมเลือกตั้ง 62</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://adaybulletin.com/talk-conversation-2019-thailand-general-election-sombat-boonngamanong/28817"
                                        target="_blank">
                                            <img async
                                                src="https://adaybulletin.com/wp-content/uploads/2019/03/feature_conversation_สมบัติ_01.jpg"
                                                className="img-fluid"
                                                alt="เลือกตั้ง 2562: บ.ก. ลายจุด | ขอเป็นไม้ประดับในการเมืองไทย สร้างสีสันให้เกิดร่องความคิดของสังคม"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://adaybulletin.com/talk-conversation-2019-thailand-general-election-sombat-boonngamanong/28817"
                                        target="_blank">
                                            <h4 className="title">เลือกตั้ง 2562: บ.ก. ลายจุด | ขอเป็นไม้ประดับในการเมืองไทย สร้างสีสันให้เกิดร่องความคิดของสังคม</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://adaybulletin.com/talk-conversation-2019-thailand-general-election-lertsak-kumkongsak/28753"
                                        target="_blank">
                                            <img async
                                                src="https://adaybulletin.com/wp-content/uploads/2019/03/feature_conversation_พรรคสามัญชน.jpg"
                                                className="img-fluid"
                                                alt="เลือกตั้ง 2562: เลิศศักดิ์ คำคงศักดิ์ | สามัญชนผู้หวังหอบหิ้วเสียงของคนตัวเล็กๆ เข้าไปในสภา"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://adaybulletin.com/talk-conversation-2019-thailand-general-election-lertsak-kumkongsak/28753"
                                        target="_blank">
                                            <h4 className="title">เลือกตั้ง 2562: เลิศศักดิ์ คำคงศักดิ์ | สามัญชนผู้หวังหอบหิ้วเสียงของคนตัวเล็กๆ เข้าไปในสภา</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://adaybulletin.com/talk-conversation-2019-thailand-general-election-pauline-ngarmpring/"
                                        target="_blank">
                                            <img async
                                                src="https://adaybulletin.com/wp-content/uploads/2019/03/feature_conversation_พอลลีน.jpg"
                                                className="img-fluid"
                                                alt="เลือกตั้ง 62: พอลลีน งามพริ้ง | ถ้าทุกคนมีความเท่าเทียม ประเทศไทยจะเดินหน้าไกลกว่าทุกวันนี้"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://adaybulletin.com/talk-conversation-2019-thailand-general-election-pauline-ngarmpring/"
                                        target="_blank">
                                            <h4 className="title">เลือกตั้ง 62: พอลลีน งามพริ้ง | ถ้าทุกคนมีความเท่าเทียม ประเทศไทยจะเดินหน้าไกลกว่าทุกวันนี้</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://adaybulletin.com/talk-conversation-2019-thailand-general-election-sereepisuth-temeeyaves/28653"
                                        target="_blank">
                                            <img async
                                                src="https://adaybulletin.com/wp-content/uploads/2019/03/feature_conversation_เสรีพิสุทธ์.jpg"
                                                className="img-fluid"
                                                alt="เลือกตั้ง 2562: เสรีพิศุทธ์ เตมียเวส | ถ้าได้เป็นนายกรัฐมนตรี ประเทศไทยจะไม่มีรัฐประหารอีก"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://adaybulletin.com/talk-conversation-2019-thailand-general-election-sereepisuth-temeeyaves/28653"
                                        target="_blank">
                                            <h4 className="title">เลือกตั้ง 2562: เสรีพิศุทธ์ เตมียเวส | ถ้าได้เป็นนายกรัฐมนตรี ประเทศไทยจะไม่มีรัฐประหารอีก</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://themomentum.co/pitha-limchareonrat-interview/"
                                        target="_blank">
                                            <img async
                                                src="https://themomentum.co/wp-content/uploads/2019/03/TheMO-ทิมพิธา_FBthumbnail-1024x535.jpg"
                                                className="img-fluid"
                                                alt="พิธา ลิ้มเจริญรัตน์ “ผมไม่ได้เตรียมตัวมาเพื่อเป็นนักธุรกิจอย่างเดียว”"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://themomentum.co/pitha-limchareonrat-interview/"
                                        target="_blank">
                                            <h4 className="title">พิธา ลิ้มเจริญรัตน์ “ผมไม่ได้เตรียมตัวมาเพื่อเป็นนักธุรกิจอย่างเดียว”</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://adaybulletin.com/talk-conversation-2019-thailand-general-election-abhisit-vejjajiva/27302"
                                        target="_blank">
                                            <img async
                                                src="https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/images%2Fcontent%2Fapisit.jpg?alt=media"
                                                className="img-fluid"
                                                alt="เลือกตั้ง 2562: อภิสิทธิ์ เวชชาชีวะ |
                                                ประชาชนยังมีสิทธิ์ในการเขียนบทให้ประเทศ"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://adaybulletin.com/talk-conversation-2019-thailand-general-election-abhisit-vejjajiva/27302"
                                        target="_blank">
                                            <h4 className="title">เลือกตั้ง 2562: อภิสิทธิ์ เวชชาชีวะ |
                                                ประชาชนยังมีสิทธิ์ในการเขียนบทให้ประเทศ</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://themomentum.co/interview-suthep-thaugsuban-thai-election-62/"
                                        target="_blank">
                                            <img async
                                                src="https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/images%2Fcontent%2F%E0%B8%AA%E0%B8%B8%E0%B9%80%E0%B8%97%E0%B8%9E.jpg?alt=media"
                                                className="img-fluid"
                                                alt="ลุงกำนัน ‘สุเทพ เทือกสุบรรณ’ มั่นใจ ชนะเกมเลือกตั้ง’ 62"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://themomentum.co/interview-suthep-thaugsuban-thai-election-62/"
                                        target="_blank">
                                            <h4 className="title">ลุงกำนัน ‘สุเทพ เทือกสุบรรณ’ มั่นใจ ชนะเกมเลือกตั้ง’ 62</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://themomentum.co/kanawat-chantaralawan-new-dem/" target="_blank">
                                            <img async
                                                src="https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/images%2Fcontent%2F%E0%B8%AB%E0%B8%A1%E0%B8%AD%E0%B9%80%E0%B8%AD%E0%B9%89%E0%B8%81.jpg?alt=media"
                                                className="img-fluid"
                                                alt="หมอเอ้ก คณวัฒน์ New Dem แห่งประชาธิปัตย์ “จะซ้ายหรือขวา ให้ว่ากันเป็นเรื่องๆ”"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://themomentum.co/kanawat-chantaralawan-new-dem/" target="_blank">
                                            <h4 className="title">หมอเอ้ก คณวัฒน์ New Dem แห่งประชาธิปัตย์ “จะซ้ายหรือขวา
                                                ให้ว่ากันเป็นเรื่องๆ”</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://adaybulletin.com/talk-conversation-2019-thailand-general-election-thanathorn-juangroongruangkit/27258"
                                        target="_blank">
                                            <img async
                                                src="https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/images%2Fcontent%2Ftanathon.jpg?alt=media"
                                                className="img-fluid"
                                                alt="เลือกตั้ง 2562: ธนาธร จึงรุ่งเรืองกิจ | ถ้าคุณไม่กล้าเสี่ยง คุณจะไม่เคยได้รับรางวัลอะไรเลยในชีวิต"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://adaybulletin.com/talk-conversation-2019-thailand-general-election-thanathorn-juangroongruangkit/27258"
                                        target="_blank">
                                            <h4 className="title">เลือกตั้ง 2562: ธนาธร จึงรุ่งเรืองกิจ | ถ้าคุณไม่กล้าเสี่ยง
                                                คุณจะไม่เคยได้รับรางวัลอะไรเลยในชีวิต</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://themomentum.co/chadchart-sittipunt-interview-2019/"
                                        target="_blank">
                                            <img async
                                                src="https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/images%2Fcontent%2Fchadchart.jpg?alt=media"
                                                className="img-fluid"
                                                alt="ชัชชาติ สิทธิพันธุ์ คนแกร่งผู้อยากเห็นการเมืองไทยไม่ดราม่า"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://themomentum.co/chadchart-sittipunt-interview-2019/"
                                        target="_blank">
                                            <h4 className="title">ชัชชาติ สิทธิพันธุ์ คนแกร่งผู้อยากเห็นการเมืองไทยไม่ดราม่า</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://themomentum.co/paiboon-nititawan-peoples-reform-party/"
                                        target="_blank">
                                            <img async
                                                src="https://themomentum.co/wp-content/uploads/2019/03/TheMo-ไพบูลย์-FB.png"
                                                className="img-fluid"
                                                alt="ไพบูลย์ นิติตะวัน ชายผู้น้อมนำ ‘พระพุทธเจ้า’ มาผนวกกับ ‘การเมือง’"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://themomentum.co/paiboon-nititawan-peoples-reform-party/"
                                        target="_blank">
                                            <h4 className="title">ไพบูลย์ นิติตะวัน ชายผู้น้อมนำ ‘พระพุทธเจ้า’ มาผนวกกับ ‘การเมือง’</h4>
                                        </a>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div id="loadmore1" className="text-center mb-4">
                            <a className="btn btn-outline">Load More</a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <h3 id="political-hashtag" ref="political-hashtag" className="heading">นโยบายพรรคการเมือง</h3>
                        <div className="news-list2">
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://themomentum.co/policy-menu-ep08-economics/"
                                        target="_blank">
                                            <img async
                                                src="https://themomentum.co/wp-content/uploads/2019/03/TheMO-Economic_FBthumbnail.jpg"
                                                className="img-fluid"
                                                alt="ประชันนโยบายพรรคการเมือง EP 08: ด้านเศรษฐกิจ"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://themomentum.co/policy-menu-ep08-economics/"
                                        target="_blank">
                                            <h4 className="title">ประชันนโยบายพรรคการเมือง EP 08: ด้านเศรษฐกิจ</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://themomentum.co/policy-menu-education/"
                                        target="_blank">
                                            <img async
                                                src="https://themomentum.co/wp-content/uploads/2019/03/TheMO-EducationPolicy_FBthumbnail.jpg"
                                                className="img-fluid"
                                                alt="ประชันนโยบายพรรคการเมือง EP06 : การศึกษา"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://themomentum.co/policy-menu-education/"
                                        target="_blank">
                                            <h4 className="title">ประชันนโยบายพรรคการเมือง EP06 : การศึกษา</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://themomentum.co/policy-menu-environment/"
                                        target="_blank">
                                            <img async
                                                src="https://themomentum.co/wp-content/uploads/2019/03/TheMO-Environment_FBthumbnail-1024x535.png"
                                                className="img-fluid"
                                                alt="ประชันนโยบายพรรคการเมือง EP05 – สิ่งแวดล้อม"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://themomentum.co/policy-menu-environment/"
                                        target="_blank">
                                            <h4 className="title">ประชันนโยบายพรรคการเมือง EP05 – สิ่งแวดล้อม</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://themomentum.co/policy-menu-science-technology/"
                                        target="_blank">
                                            <img async
                                                src="https://themomentum.co/wp-content/uploads/2019/03/TheMO-TransportationPolicy_FBthumbnail.png"
                                                className="img-fluid"
                                                alt="ประชันนโยบายพรรคการเมือง EP 04: ด้านวิทยาศาสตร์และเทคโนโลยี"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://themomentum.co/policy-menu-science-technology/"
                                        target="_blank">
                                            <h4 className="title">ประชันนโยบายพรรคการเมือง EP 04: ด้านวิทยาศาสตร์และเทคโนโลยี</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://themomentum.co/policy-menu-art-culture/"
                                        target="_blank">
                                            <img async
                                                src="https://themomentum.co/wp-content/uploads/2019/03/TheMO-artandculture_FBthumbnail.png"
                                                className="img-fluid"
                                                alt="ประชันนโยบายพรรคการเมือง EP 03: ด้านศิลปวัฒนธรรม"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://themomentum.co/policy-menu-art-culture/"
                                        target="_blank">
                                            <h4 className="title">ประชันนโยบายพรรคการเมือง EP 03: ด้านศิลปวัฒนธรรม</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>    
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://themomentum.co/policy-menu-transport/"
                                        target="_blank">
                                            <img async
                                                src="https://themomentum.co/wp-content/uploads/2019/03/TheMO-TransportationPolicy_FBthumbnail2.jpg"
                                                className="img-fluid"
                                                alt="ประชันนโยบายพรรคการเมือง EP02 – คมนาคม"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://themomentum.co/policy-menu-transport/"
                                        target="_blank">
                                            <h4 className="title">ประชันนโยบายพรรคการเมือง EP02 – คมนาคม</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://themomentum.co/policy-menu-health/"
                                        target="_blank">
                                            <img async
                                                src="https://themomentum.co/wp-content/uploads/2019/03/TheMO-Healthcare_FBthumbnail3.jpg"
                                                className="img-fluid"
                                                alt="ประชันนโยบายพรรคการเมือง EP01 – สุขภาพ"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://themomentum.co/policy-menu-health/"
                                        target="_blank">
                                            <h4 className="title">ประชันนโยบายพรรคการเมือง EP01 – สุขภาพ</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://themomentum.co/10-parties-for-election-2019/"
                                        target="_blank">
                                            <img async
                                                src="https://themomentum.co/wp-content/uploads/2019/03/Cover-TheMoB-10พรรคการเมือง.png"
                                                className="img-fluid"
                                                alt="สำรวจวาทะของ 10 ผู้นำพรรคการเมืองไทย"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://themomentum.co/10-parties-for-election-2019/"
                                        target="_blank">
                                            <h4 className="title">สำรวจวาทะของ 10 ผู้นำพรรคการเมืองไทย</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                                                          
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://ilaw.or.th/node/5194"
                                        target="_blank">
                                            <img async
                                                src="https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/images%2Fcontent%2Fnews01.png?alt=media"
                                                className="img-fluid"
                                                alt="รู้จักพรรคทางเลือก"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://ilaw.or.th/node/5194"
                                        target="_blank">
                                            <h4 className="title">รู้จักพรรคทางเลือก</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>   
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://ilaw.or.th/node/5147"
                                        target="_blank">
                                            <img async
                                                src="https://ilaw.or.th/sites/default/files/u12/political-party-position.jpg"
                                                className="img-fluid"
                                                alt="เลือกตั้ง62: สำรวจจุดยืน พรรคการเมือง อยู่ตำแหน่งไหนบ้างในสนามเลือกตั้ง"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://ilaw.or.th/node/5147"
                                        target="_blank">
                                            <h4 className="title">เลือกตั้ง62: สำรวจจุดยืน พรรคการเมือง อยู่ตำแหน่งไหนบ้างในสนามเลือกตั้ง</h4>
                                        </a>
                                    </div>
                                </div>    
                            
                            </div>   
                            <div className="news-list-item">
                                <div className="row no-gutters mb-3 mb-md-4">
                                    <div className="col-5">
                                        <a href="https://adaybulletin.com/article-agenda-before-thailand-election-2019/"
                                        target="_blank">
                                            <img async
                                                src="https://adaybulletin.com/wp-content/uploads/2019/03/feature_agenda_posttimline_election.jpg"
                                                className="img-fluid"
                                                alt="เลือกตั้ง 2562: 5 ปีนานพอหรือยัง! สำรวจไทม์ไลน์สุญญากาศการเมืองกว่าจะมาถึงเลือกตั้ง ’62"/>
                                        </a>
                                    </div>
                                    <div className="col-7 pl-2 pl-md-3">
                                        <a href="https://adaybulletin.com/article-agenda-before-thailand-election-2019/"
                                        target="_blank">
                                            <h4 className="title">เลือกตั้ง 2562: 5 ปีนานพอหรือยัง! สำรวจไทม์ไลน์สุญญากาศการเมืองกว่าจะมาถึงเลือกตั้ง ’62</h4>
                                        </a>
                                    </div>
                                </div>
                                
                            </div>   
                        </div>    

                        <div id="loadmore2" className="text-center">
                            <a className="btn btn-outline">Load More</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
