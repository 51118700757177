import React from 'react'
import PageComponent from './PageComponent.jsx'
import Link from 'react-router-dom/es/Link'

export default class CrowdSourcingRandom extends PageComponent {
    constructor(props) {
        super(props)
        this.hub = props.hub

        this.state = {
            loaded: false,
            error: false
        }
    }

    componentDidMount = () => {
        this.fetch()
    }

    fetch = async () => {
        this.setState({loaded: false, error: false})

        let callable = await this.hub.functions.httpsCallable('randomSubmissionsCallable')
        try {
            let result = await callable({env: {'development': 'dev', 'production': 'prod'}[this.hub.appMode]})

            if (result && result.data.id) {
                this.props.history.push(`/crowdsource/digitize/${result.data.id}`)
            } else {
                this.setState({loaded: true})
            }
        } catch (e) {
            console.log(e)
            this.setState({loaded: true, error: e})
        }
    }

    gotoUpload = () => {
        this.props.history.push(`/crowdsource/upload`)
    }

    render = () => {
        const {loaded, error} = this.state
        return (
            <div className="container crowdsourcing -random">
                {loaded ?
                    <div className="wrapper-height100 d-flex justify-content-center align-items-center flex-column">
                        <h3 className="text-center">ยังไม่มีข้อมูล</h3>

                        <div className="row">
                            <div className="col text-center">
                                {!error ?
                                    <div>
                                        <div className="lead">คุณสามารถช่วยอัปโหลดรูปภาพผลคะแนนจากหน่วยเลือกตั้งได้</div>
                                        <button className="btn btn-lg btn-nc btn-dark mt-3" onClick={this.gotoUpload}>อัปโหลดข้อมูล</button>
                                    </div> :
                                    <div>
                                        <div className="lead">มีบางอย่างผิดพลาด</div>
                                        <button className="btn btn-lg btn-nc btn-dark mt-3" onClick={this.fetch}>ลองสุ่มใหม่</button>
                                    </div>
                                }

                                <div className="mt-3">
                                    <Link className="btn btn-nc btn-md" to="/">กลับหน้าหลัก</Link>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className="wrapper-height100 d-flex justify-content-center align-items-center flex-column">
                        <h3 className="text-center">กำลังสุ่มข้อมูล</h3>

                        <div className="text-center">
                            <p className="lead">กรุณารอสักครู่</p>
                            <img src="/static/images/loading2.gif" alt="loading ..." />
                        </div>
                    </div>
                }
            </div>
        )
    }
}