import React from "react";

import Party from "../data/party.json";

import {Link} from "react-router-dom";
import VisualPartyProgress from "./VisualPartyProgress.jsx";

const popularSort = (a, b) => {
    return (a.total > b.total) ? -1 : ((b.total > a.total) ? 1 : 0)
}

function numberWithCommas(x) {
    return (x || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}



export default class VisualProgress extends React.Component {

    constructor(props) {
        super(props);

        this.hub = props.hub;
        this.defaultDataSourceCode = 'crowdsource';

        this.lockProvince = 'สมุทรปราการ'
        this.lockZone = '5'

        this.candidateMap = {}
        this.hub.candidates.forEach(candidate => {
            this.candidateMap[candidate.party] = candidate
        })

        this.state = {
            loading: true
        }

    }


    async getEct100DataSource() {
        let url = 'https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/data%2Fect100.csv?alt=media';
        let resp = await axios.get(url);
        let data = Papa.parse(resp.data, {header: true}).data;

        let candidateMap = {};
        this.hub.candidates.forEach(c => {
            if (!candidateMap[c.province]) candidateMap[c.province] = {};
            if (!candidateMap[c.province][c.zone]) candidateMap[c.province][c.zone] = {};
            if (!candidateMap[c.province][c.zone][c.no]) candidateMap[c.province][c.zone][c.no] = c;
        });
        
        let result = {province: [], popular: [], total: 0};
        let provinceMap = {}, provinceList = [];
        let popularMap = {}, popularList = [];

        let csv = '';

        data.forEach(row => {
            let province = row.province; // TODO: clean province
            // get candidate from this.hub.candidates map with row data
            let candidate = candidateMap[row.province][row.zone][row.no];
            province = candidate.province;

            let zone = candidate.zone;
            let party = candidate.party;

            if (!provinceMap[candidate.province]) provinceMap[province] = {key: province, total: 0, party: [], zones: {}};
            if (!provinceMap[candidate.province].zones[zone]) provinceMap[province].zones[zone] = {key: zone, total: 0, party: []};
            if (!popularMap[party]) popularMap[party] = {key: party, total: 0};

            let score = parseInt(row.score);

            result.total += score;

            provinceMap[province].zones[zone].total += score;
            provinceMap[province].zones[zone].party.push({
                key: candidate.party,
                total: score
            });

            popularMap[party].total += score;
        });


        Object.keys(provinceMap).map(function(provinceKey) {
            let province = provinceMap[provinceKey];
            let zoneList = [];
            Object.keys(province.zones).map(function(zoneKey) {
                let zone = provinceMap[provinceKey].zones[zoneKey];
                zoneList.push(zone);
            });
            province.zones = zoneList;
            provinceList.push(province);
        });
        result.province = provinceList;

        Object.keys(popularMap).map(function(popularKey) {
            let popular = popularMap[popularKey];
            popularList.push(popular);
        });
        result.popular = popularList;

        result.noVoteTotal = 605392;
        result.voidedTotal = 2130327;

        return result;


    }


    async getCrowdDataSource() {
        try {
            let ref = await this.hub.db.doc(`${this.hub.appMode}--cache/elect-results--people`).get()
            if (ref.exists) {
                return JSON.parse(ref.data().data)
            } else {
                return {}
            }
        } catch (e) {
            console.error(e)
        }
    }

    getDataSourcePath = (source) => {
        const appMode = this.hub.appMode

        let path = {
            'crowdsource': `${appMode}--cache/elect-results--people`
        }

        return path[source]
    }



    async getDataSource(code) {
        code = code || this.state.dataSourceCode

        if (this.realtimeUnsubscribe) {
            this.realtimeUnsubscribe()
        }

        let resultsPromise
        if (code === 'crowdsource') {
            resultsPromise = this.getCrowdDataSource()
        } if (code === 'ect100') {
            resultsPromise = this.getEct100DataSource()
        } else {
            resultsPromise = []
        }

        let results = await resultsPromise
        const endpoint = this.getDataSourcePath(code)

        if (endpoint) {
            this.realtimeUnsubscribe = this.hub.db.doc(endpoint).onSnapshot(snapshot => {
                console.log('-> receive realtime data', code)
                if (snapshot.data()) {
                    this.updateDataSource(code, JSON.parse(snapshot.data().data || '{}'))
                }
            }, e => {
                console.log('-> realtime stream error', e)
            })
        }

        return results
    }


    async updateDataSource(code, preloadedDataSource, forceUpdate) {
        let dataSource;
        let cache = JSON.parse(localStorage.getItem(`cache-dataSource-${code || this.defaultDataSourceCode}`));
        let hit = cache && (Date.now() - parseInt(cache.timestamp)) < 1*60*1000;

        if (false && hit && !preloadedDataSource) { // TODO: get data source from cache every 1 minute (success) or when receive realtime message from firebase

            dataSource = cache.value;

        } else { // build data source

            let forceUpdate = Boolean(preloadedDataSource) || forceUpdate;

            this.setState({
                preparing: true,
                forceUpdate: forceUpdate
            });
            if (!preloadedDataSource) {
                dataSource = await this.getDataSource(code)
            } else {
                dataSource = preloadedDataSource
            }


            let partyMP = {};
            let partyScore = {};

            // Faster by index map and ordering

            if (dataSource.popular) {
                dataSource.popular.sort(popularSort);
                dataSource.popular.forEach((party) => {
                    if (party.key !== 'noVote' && party.key !== 'voided') {
                        partyScore[party.key] = party.total;
                    }
                });
            }


            dataSource.succeedTotal = 0;
            dataSource.noVoteTotal = dataSource.noVoteTotal || 0;
            dataSource.voidedTotal = dataSource.voidedTotal || 0;
            dataSource.goodVoteTotal = 0;


            if (dataSource.province) {
                dataSource.province = dataSource.province.reduce((obj1, province) => {

                    province.zones = province.zones.reduce((obj2, zone) => {
                        zone.party.sort(popularSort);

                        // provide score for party list
                        let winnerParty = null;
                        let noVote = null;
                        let voided = null;

                        let onlyParty = zone.party.filter((party, i) => {
                            if (party.key === 'noVote') {
                                noVote = party;
                            }
                            if (party.key === 'voided') {
                                voided = party;
                            }
                            return party.key !== 'noVote' && party.key !== 'voided';
                        });

                        if (onlyParty.length >= 1 && noVote && noVote.total >= onlyParty[0].total) {
                            // re vote
                        } else if (onlyParty.length >= 2 && onlyParty[0].total === onlyParty[1].total) {
                            // draw
                        } else if (onlyParty.length >= 1 && onlyParty.filter(p => p.total > 0).length >= 3) {
                            winnerParty = onlyParty[0];
                        }
                        zone.winnerParty = winnerParty;
                        zone.succeed = zone.party.filter(it => it.total > 0).length > 0

                        if (zone.succeed) {
                            dataSource.succeedTotal++;
                        }
                        if (noVote) {
                            dataSource.noVoteTotal += noVote.total;
                        }
                        if (voided) {
                            dataSource.voidedTotal += voided.total;
                        }
                        dataSource.goodVoteTotal += onlyParty.reduce((a, b) => a + b.total, 0);


                        if (winnerParty) {
                            if (!partyMP[winnerParty.key]) {
                                partyMP[winnerParty.key] = 0;
                            }
                            partyMP[winnerParty.key]++;
                        }

                        obj2[zone.key] = zone;

                        return obj2;
                    }, {});

                    province.party.sort(popularSort);
                    obj1[province.key] = province;

                    return obj1
                }, {});
            }

            // calculate party list

            const totalScore = Object.keys(partyScore).reduce((a, key) => a + partyScore[key], 0);
            const score4Rep = totalScore / 500;

            const calPartyList = () => {

                let partyShouldMP = [];

                for (const key of Object.keys(partyScore)) {

                    const zoneTotal = partyMP[key] || 0;
                    const score = partyScore[key];
                    const partyRep = score4Rep ? score / score4Rep : 0;
                    const partyRepCeilling = Math.floor(partyRep);
                    const partyListCount = Math.max(0, partyRepCeilling - zoneTotal);


                    const partyName = key.replace('พรรค', '');

                    partyShouldMP.push({
                        key: key,
                        color: Party[partyName].color,
                        shortName: Party[partyName].shortName,
                        total: partyRep,
                        partyListCount: partyListCount,
                        moreTotal: partyListCount,
                        zoneTotal: zoneTotal,
                        allTotal: zoneTotal + partyListCount,
                        score: score
                    });
                }
                partyShouldMP.sort(popularSort);

                return partyShouldMP;
            }

            let partyShouldMP = calPartyList();
            let sumPartyList = partyShouldMP.reduce((a, p) => a + p.partyListCount, 0);

            if (sumPartyList > 150) {
                let popularPartyListDecimal = [];
                let partyShouldMPMap = {};

                partyShouldMP.forEach(p => {
                    let partyListCount = p.partyListCount*150/sumPartyList;
                    let partyListDecimal = partyListCount - Math.floor(partyListCount);
                    p.partyListCount = Math.floor(partyListCount);
                    p.moreTotal = p.partyListCount; // alias
                    partyShouldMPMap[p.key] = p;
                    popularPartyListDecimal.push({key: p.key, total: partyListDecimal})
                });
                popularPartyListDecimal.sort(popularSort);

                let remainingMP = 150 - partyShouldMP.reduce((a, p) => a + p.partyListCount, 0);
                popularPartyListDecimal.forEach(p => {
                    if (remainingMP > 0) {
                        partyShouldMPMap[p.key].partyListCount++;
                        partyShouldMPMap[p.key].moreTotal = partyShouldMPMap[p.key].partyListCount;
                        remainingMP--;
                    }

                });


            } else {
                // TODO: increase MP by decimal
            }
            sumPartyList = partyShouldMP.reduce((a, p) => a + p.partyListCount, 0);


            let remainingMP = 150;
            partyShouldMP.forEach(party => {
                party.finalMoreTotal = Math.min(party.moreTotal, remainingMP);
                party.allTotal = party.zoneTotal + party.finalMoreTotal;
                remainingMP -= party.finalMoreTotal;
            });


            dataSource.partyShouldMP = partyShouldMP;

        }


        // serve and set state from cache or build data

        let partyMPList = [].concat(dataSource.partyShouldMP);
        partyMPList.sort((a,b) => (a.allTotal > b.allTotal) ? -1 : ((b.allTotal > a.allTotal) ? 1 : 0));

        let newState = {
            dataSource: dataSource,
            partyMPList: partyMPList,
            selectedParty: {},
            preparing: false,
            forceUpdate: false
        };
        if (code) {
            newState.dataSourceCode = code;
        }
        this.setState(newState);

        setTimeout(() => this.setState({loading: false}), 1000)

        if (!hit || preloadedDataSource) {
            cache = JSON.stringify({value: dataSource, timestamp: Date.now()});
            localStorage.setItem(`cache-dataSource-${code || this.defaultDataSourceCode}`, cache);
        }
    }



    checkActiveSource = source => {
        // check active tab from hash
        this.updateDataSource(source, null, true)
    }
    
    componentWillMount() {
        // this.urlUnlisten = this.props.history.listen((location) => {
        //     const source = location.pathname.split('/')[2]
        //     // setTimeout(() => this.hub.logStat(this.pageCode, `motion-select-data-source-${code}`))
        //     this.checkActiveSource(source)
        // })
    }
    componentWillUnmount() {
        // if (this.urlUnlisten) this.urlUnlisten()
    }

    componentDidMount() {
        this.updateDataSource(this.defaultDataSourceCode, null, true)

        // window.scrollTo(0, 0);
        // this.hub.pushPath(this.pageCode);
        // this.hub.logStat(this.pageCode, 'motion-page');
    }

    render() {

        if (this.state.loading) {
            const loader = (
                <div className="report-loading-layer-wrapper text-center" style={{zIndex: '9999'}}>
                    <div className="report-loading-layer" style={{width: '600px', height: '400px', backgroundColor: 'rgba(0,0,0,0.4)'}}>
                        <div className="mx-auto rounded p-4" style={{maxWidth: '200px', width: '100%', backgroundColor: 'rgba(255,255,255,0.8)'}}>
                            <img src="/static/images/preloading.gif" alt="loading ..." style={{width: '100%', height: 'auto', maxWidth: '200px'}}/>
                        </div>
                    </div>
                </div>
            )
            return loader
        }

        console.log('this.state.dataSource', this.state.dataSource)

        if (!this.state.dataSource || !this.state.dataSource.province) {
            return ''
        }
        let totalPeople = 0

        let progress = {party: []}
        if (this.state.dataSource.province[this.lockProvince]) {
            progress = this.state.dataSource.province[this.lockProvince].zones[this.lockZone]
        }
        
        // let totalPeople = 132397
        progress.party.forEach(party => {
            totalPeople += party.total
        })

        return (
            
            <div className="py-4 px-3 bg-light-grey">
                {/* TODO : Mockup content */}
                <div className="px-0 container candidate-motion-header position-relative mb-3 d-none">
                    <div className="condidate-motion-wrapper p-2 px-sm-3 pt-sm-3 pb-sm-2">
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <div className="candidate-motion-source d-flex flex-row justify-content-start justify-content-sm-center align-items-center _fs-18 _fw-bd">
                                <div className="mr-1 mr-sm-3"><strong>ผลการนับคะแนนจาก</strong></div>
                                {/* <button type="button" onClick={() => {this.hub.logStat(this.pageCode, 'motion-select-data-source-ect100'); this.gotoSource('')}} className={`px-2 py-1 btn mr-1 mr-sm-3${this.state.dataSourceCode === 'ect100'? ' btn-secondary active': ''}`}>กกต. 100%</button>
                                <button type="button" onClick={() => {this.hub.logStat(this.pageCode, 'motion-select-data-source-ect'); this.gotoSource('ect94')}} className={`px-2 py-1 btn mr-1 mr-sm-3${this.state.dataSourceCode === 'ect'? ' btn-secondary active': ''}`}>กกต. 94%</button> */}
                                <button type="button" className={`px-2 py-1 btn mr-1 mr-sm-3`}>กกต.</button>
                                <button type="button" className={`px-2 py-1 btn mr-1 mr-sm-3`}>ทางบ้าน</button>
                                {/* <button type="button" onClick={() => {this.updateDataSource('statistic')}} className={`d-none px-2 py-1 btn mr-1 mr-sm-3${this.state.dataSourceCode === 'statistic'? ' btn-secondary active': ''}`}>สถิติ</button> */}
                            </div>
                            <div className="candidate-motion-source d-flex flex-row justify-content-start justify-content-sm-center align-items-center _fs-18 _fw-bd">
                                {/* <div className="mr-1 mr-sm-3"><strong>อับเดทล่าสุด </strong><br />20:00 น.</div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center text-white">
                    <h1 className="_lh-1 _fs-30 _fs-sm-42">ผลการรายงานคะแนนเลือกตั้งซ่อม สมุทรปราการ <br className="d-md-block d-none" />เขตเลือกตั้งที่ 5 แบบ Crowdsourcing</h1>
                    <p className="_fw-med _fs-21 _lh-1">จากการช่วยกันถ่ายรูปและกรอกข้อมูลคะแนนมายัง Vote62</p>
                </div>

                <VisualPartyProgress hub={this.hub} progress={progress} />

                <div className="d-none text-center mt-4">
                    <Link to={`/visual/search`} className="btn btn-black bg-black badge-pill py-2 px-3 text-white d-flex flex-row align-items-center justify-content-center mx-auto" style={{ maxWidth: '200px' }}>
                        <img className="mr-2" src="/static/images/icon-choak-white.png" srcSet="/static/images/icon-choak-white.png 1x, /static/images/icon-choak-white@2x.png 2x" /> <span className="_fs-22 _fw-reg">ดูคะแนนแต่ละหน่วย</span>
                    </Link>
                </div>

                <div className="d-flex flex-wrap align-items-center justify-content-center">
                    <div className="px-2 text-center mt-4">
                        <Link to={`/visual/search`} className="btn btn-black bg-black badge-pill py-2 px-3 text-white d-flex flex-row align-items-center justify-content-center" style={{ maxWidth: '200px' }}>
                            <img className="mr-2" src="/static/images/icon-choak-white.png" srcSet="/static/images/icon-choak-white.png 1x, /static/images/icon-choak-white@2x.png 2x" /> <span className="_fs-22 _fw-reg">ดูคะแนนแต่ละหน่วย</span>
                        </Link>
                    </div>

                    <div className="px-2 text-center mt-4">
                        <a href="https://pvtthailand.vote62.com/" target="_blank" className={`btn btn-black bg-black badge-pill py-2 px-3 text-white d-flex flex-row align-items-center justify-content-center`}>
                            <img className="mr-2" src="/static/images/ic-indicator.png" srcSet="/static/images/ic-indicator.png 1x, /static/images/ic-indicator@2x.png 2x" width="40" /> <span className="_fs-22 _fw-reg">ดูผลประเมินกระบวนการจัดการเลือกตั้ง</span>
                        </a>
                    </div>
                </div>

            </div>

        )
    }

}