import React from "react";

import {Link} from "react-router-dom";
import PageComponent from "./PageComponent.jsx";
import VisualCandidate from "./VisualCandidate.jsx";
import VisualProgress from "./VisualProgress.jsx";
import VisualUnitResult from "./VisualUnitResult.jsx";
import VisualSidebar from "./VisualSidebar.jsx";

// import Party from "../data/party.json";


export default class Motion extends PageComponent {

    constructor(props) {
        super(props);

        this.hub = props.hub;
        this.lockProvince = 'สมุทรปราการ'
        this.lockZone = '5'

        this.state = {
          unitResult: null
        }

    }

    async componentDidMount() {

      let callable = await this.hub.functions.httpsCallable('cleanedVoteResultsCallable')
      try {
          let result = await callable({env: this.hub.appMode, source: 'people'})

          if (result.data[this.lockProvince]) {
            let unitResult = result.data[this.lockProvince][this.lockZone]
            this.setState({unitResult: unitResult})
          }
          
      } catch (e) {
          console.log('err', e)
          // this.setState({loaded: true, error: e})
      }
    }

    render() {
        
        return (
            <div>
                <div className={`visual-wrapper d-flex flex-md-row flex-column justify-content-between`}>
                    <div className="left">

                        <div className="bg-dark-grey">
                            <div className="container p-3">
                                <a href="https://pvtthailand.vote62.com/" target="_blank" className={`text-center text-white pvt-banner`}>
                                    <div className={`heading d-flex align-items-end flex-nowrap justify-content-center mb-3`}>
                                        <h3 className={`mb-0`}>ดูผลประเมิน<span className={`pvt-color d-inline-block`}>กระบวนการจัดการเลือกตั้ง</span></h3> <div className={`ml-3 d-none`}><img src="/static/images/ic-indicator.png" srcSet="/static/images/ic-indicator.png 1x, /static/images/ic-indicator@2x.png 2x" /></div>
                                    </div>
                                    <div className={`subtext-1`}>จากการประเมินของอาสาสมัคร <span className="d-inline-block">ผ่านเครื่องมือการสังเกตการณ์เลือกตั้ง</span></div>
                                    <div className={`subtext-2 mb-0`}><span className={`pvt-color`}>โดย <strong>PVT Thailand</strong></span><br /><u><span>คลิกที่นี่ ›</span></u></div>
                                </a>
                                <style jsx>{`
                                    .pvt-color {
                                        color: #47E7A0;
                                    }
                                    .pvt-banner {
                                        margin: 0 auto;
                                        max-width: 850px;
                                        width: fit-content;
                                        display: block;
                                        padding: 30px 20px;
                                        background: #35373C url(/static/images/pvt-bg.png) no-repeat center;
                                        background-size: 70%;
                                        background-position: center;
                                        border-radius: 20px;
                                        border: 1px solid #979797;
                                        font-family: 'DB Helvethaica X New';
                                    }
                                    .pvt-banner:hover {
                                        text-decoration: none;
                                        background-color: #26282D;
                                    }
                                    .heading > h3 {
                                        font-size: 47px;
                                        line-height: 0.55em
                                    }
                                    .heading img {
                                        width: 92px;
                                        height: auto;
                                    }
                                    .subtext-1 {
                                        font-size: 1.9rem;
                                        line-height: 1;
                                    }
                                    .subtext-2 {
                                        line-height: 1;
                                    }
                                    @media screen and (max-width: 767px) {
                                        .pvt-banner {
                                            background-size: contain;
                                            padding: 20px 8px;
                                        }
                                    }
                                    @media screen and (max-width: 576px) {
                                        .pvt-banner > .heading > h3 {
                                            font-size: 34px;
                                        }
                                        .pvt-banner > .heading img {
                                            width: 70px;
                                        }
                                        .pvt-banner > .subtext-1 {
                                            font-size: 1.2rem;
                                        }
                                        .pvt-banner > .subtext-2 {
                                            font-size: 1.2rem;
                                        }
                                    }
                                    @media screen and (max-width: 414px) {
                                        .pvt-banner > .heading > h3 {
                                            font-size: 28px;
                                            line-height: 0.75em;
                                        }
                                        .pvt-banner > .heading img {
                                            width: 50px;
                                        }
                                    }
                                `}</style>
                            </div>
                        </div>

                        <VisualCandidate hub={this.hub} />
                        <VisualProgress hub={this.hub} unitResult={this.state.unitResult} />

                        <div className="pt-4 bg-dark-grey">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-lg-4 order-2 order-lg-1 d-flex flex-column align-items-center justify-content-end">
                                        <img src="/static/images/volunteer.png" srcSet="/static/images/volunteer.png 1x, /static/images/volunteer@2x.png 2x" />
                                    </div>
                                    <div className="col-12 col-lg-8 order-1 order-lg-2 text-white">
                                        <h2>ไม่ว่าจะเป็นคนสมุทรปราการหรือไม่</h2>
                                        <p className="_fw-reg _fs-20">หลังปิดคูหา เวลาราว 17:00-22:00 น. สามารถมีส่วนร่วมผ่าน Vote62</p>
                                        <div className="row">
                                            <div className="col-12 col-sm-6 mb-0 mb-sm-3 d-flex align-items-start flex-row">
                                                <img src="/static/images/icon-camera-white.png" srcSet="/static/images/icon-camera-white.png 1x, /static/images/icon-camera-white@2x.png 2x"  style={{ flex: '0 0 36px', width: '36px', height: '28px' }}/>
                                                <div className="bg-orange text-center text-white rounded-circle mx-2 _fs-24 _fw-bd" style={{ flex: '0 0 28px', width: '28px', height: '28px', lineHeight: '28px' }}>1</div>
                                                <p className="_fw-reg _fs-20 text-white">ไปดูการนับคะแนนที่หน่วยเลือกตั้งเพื่อถ่ายรูปกระดานที่นับคะแนนเสร็จแล้วอัปโหลดรูปภาพเก็บไว้ในระบบ</p>
                                            </div>
                                            <div className="col-12 col-sm-6 mb-0 mb-sm-3 d-flex align-items-start flex-row">
                                                <img src="/static/images/icon-report-white.png" srcSet="/static/images/icon-report-white.png 1x, /static/images/icon-report-white@2x.png 2x" style={{ flex: '0 0 33px', width: '33px', height: '33px' }}/>
                                                <div className="bg-orange text-center text-white rounded-circle mx-2 _fs-24 _fw-bd" style={{ flex: '0 0 28px', width: '28px', height: '28px', lineHeight: '28px' }}>2</div>
                                                <p className="_fw-reg _fs-20 text-white">ช่วยกัน Digitize คะแนน หรือดูภาพแล้วกรอกตัวเลขคะแนน ยิ่งกรอกซ้ำ กรอกมาก ยิ่งมั่นใจในความแม่นยำ</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <VisualUnitResult hub={this.hub} unitResult={this.state.unitResult} />

                        <div className="partners py-4 bg-light-grey">
                            <div className="container">
                                <h2 className="text-center text-white _fs-32 mb-3">ภาคีเครือข่าย</h2>
                                <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
                                    <div className="px-3 py-2"><img src="/static/images/logo-partner/king-prapok-logo.png" srcSet="/static/images/logo-partner/king-prapok-logo.png 1x, /static/images/logo-partner/king-prapok-logo@2x.png 2x"  /></div>
                                    <div className="px-3 py-2"><img src="/static/images/logo-partner/wewatch-logo.png" srcSet="/static/images/logo-partner/wewatch-logo.png 1x, /static/images/logo-partner/wewatch-logo@2x.png 2x" /></div>
                                    <div className="px-3 py-2"><img src="/static/images/logo-partner/opendream-logo.png" srcSet="/static/images/logo-partner/opendream-logo.png 1x, /static/images/logo-partner/opendream-logo@2x.png 2x" /></div>
                                    <div className="px-3 py-2"><img src="/static/images/logo-partner/aday-bulletin-logo.png" srcSet="/static/images/logo-partner/aday-bulletin-logo.png 1x, /static/images/logo-partner/aday-bulletin-logo@2x.png 2x"  /></div>
                                    <div className="px-3 py-2"><img src="/static/images/logo-partner/ilaw-logo.png" srcSet="/static/images/logo-partner/ilaw-logo.png 1x, /static/images/logo-partner/ilaw-logo@2x.png 2x" /></div>
                                    {/* <div className="px-3 py-2"><img src="/static/images/logo-partner/kalasin-u-logo.png" srcSet="/static/images/logo-partner/kalasin-u-logo.png 1x, /static/images/logo-partner/kalasin-u-logo@2x.png 2x" /></div> */}
                                    {/* <div className="px-3 py-2"><img src="/static/images/logo-partner/khon-kaen-u-logo.png" srcSet="/static/images/logo-partner/khon-kaen-u-logo.png 1x, /static/images/logo-partner/khon-kaen-u-logo@2x.png 2x"  /></div> */}
                                    <div className="px-3 py-2"><img src="/static/images/logo-partner/the-momentum-logo.png" srcSet="/static/images/logo-partner/the-momentum-logo.png 1x, /static/images/logo-partner/the-momentum-logo@2x.png 2x" /></div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={`right`}>
                        <VisualSidebar />
                    </div>
                    
                </div>
            </div>
        )
    }

}