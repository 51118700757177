import React from "react";

import {Link} from "react-router-dom";
import PageComponent from "./PageComponent.jsx";
import VisualSidebar from "./VisualSidebar.jsx";
import VisualPartyProgress from "./VisualPartyProgress.jsx";

// import Party from "../data/party.json";

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export default class VisualSearchProgress extends PageComponent {

    constructor(props) {
        super(props);

        this.hub = props.hub;
        this.lockProvince = 'สมุทรปราการ'
        this.lockZone = '5'

        this.provinces = this.hub.zones.map(z => ({id: z.id, name: z.name, children: z.children})).sort((a, b) => {
            const x = a.name.replace(/^([เแโไใ])(.)/, '$2$1')
            const y = b.name.replace(/^([เแโไใ])(.)/, '$2$1')

            if (x > y) {
                return 1
            }
            if (y > x) {
                return -1
            }

            return 0
        })
        this.provincesMap = {}
        this.provinces.forEach(p => {
            this.provincesMap[p.name] = p.id
        })



        this.state = {
            // unitResult: null,

            // dataSource: this.getCrowdDataSource(),

            province: this.lockProvince,
            zone: this.lockZone,
            zones: [],
            amphoe: '',
            amphoes: [],
            tambon: '',
            tambons: [],
            unit: '',
            units: [],

        }



    }

    async componentDidMount() {

        let callable = await this.hub.functions.httpsCallable('cleanedVoteResultsCallable')
        try {
            let result = await callable({env: this.hub.appMode, source: 'people'})

            let unitResult = result.data
            this.setState({unitResult: unitResult})

            // console.log('unitResult', unitResult)
            
        } catch (e) {
            console.log('err', e)
            // this.setState({loaded: true, åerror: e})
        }

        let amphoe = ''
        let tambon = ''
        let unit = ''
        let selectedLocation = getUrlParameter('location')
        
        if (selectedLocation) {
            selectedLocation = selectedLocation.split('--')
            amphoe = selectedLocation[2]
            tambon = selectedLocation[3]
            unit = selectedLocation[4]

        }


        this.onProvinceChange({target: {value: this.lockProvince}});
        this.onZoneChange({target: {value: this.lockZone}});
        this.onAmphoeChange({target: {value: amphoe}});
        this.onTambonChange({target: {value: tambon}});
        this.onUnitChange({target: {value: unit}});

    }

    async getCrowdDataSource() {
        try {
            let ref = await this.hub.db.doc(`${this.hub.appMode}--cache/elect-results--people`).get()
            if (ref.exists) {
                return JSON.parse(ref.data().data)
            } else {
                return {}
            }
        } catch (e) {
            console.error(e)
        }
    }
    

    
    onProvinceChange = (ev) => {

        let zones = this.provinces.filter(province => province.name === this.lockProvince)[0].children;
        
        this.setState({
            province: ev.target.value || this.lockProvince,
            zone: this.lockZone,
            zones: zones,
            amphoe: '',
            amphoes: [],
            tambon: '',
            tambons: [],
            unit: '',
            units: [],
        })

        
    }

    onZoneChange = (ev) => {


        let amphoes = this.state.zones.filter(zone => zone.name === this.lockZone)[0].children;
        

        this.setState({
            zone: this.lockZone,
            amphoe: '',
            amphoes: amphoes,
            tambon: '',
            tambons: [],
            unit: '',
            units: [],
        })
    }

    onAmphoeChange = (ev) => {

        let tambons = [];
        if (ev.target.value) {
            tambons = this.state.amphoes.filter(amphoe => amphoe.name === ev.target.value)[0].children;
        }
        this.setState({
            amphoe: ev.target.value,
            tambon: '',
            tambons: tambons,
            unit: '',
            units: [],
        })
    }

    onTambonChange = (ev) => {

        let units = [];
        if (ev.target.value) {
            units = this.state.tambons.filter(tambon => tambon.name === ev.target.value)[0].children;
        }

        this.setState({
            tambon: ev.target.value,
            unit: '',
            units: units
        })
    }

    onUnitChange = async (ev) => {
        let unit = ev.target.value

        this.setState({
            unit: unit
        })

        if (unit) {
            let results = await this.hub.db.collection(`${this.hub.appMode}--vote-results`)
                .where('parentId', '==', '')
                .where('hasFiles', '==', true)
                .where('markAsAbuse', '==', false)
                .where('crowdProvince', '==', this.state.province)
                .where('crowdZone', '==', `${this.state.zone}`)
                .where('crowdAmphoe', '==', `${this.state.amphoe}`)
                .where('crowdTambon', '==', `${this.state.tambon}`)
                .where('crowdUnit', '==', `${unit}`)
                .orderBy('created', 'asc')
                .get().then(qs => {
                    let r = [];
                    qs.forEach(item => {
                        r.push({id: item.id, ...item.data()});
                    });
                    return r;
                });


            // console.log(results)


            let groupResults = []
            let  groupResultsMap = {}

            results.forEach(result => {
                let levels = []
                if (result.crowdAmphoe) levels.push(`อ.${result.crowdAmphoe}`);
                if (result.crowdTambon) levels.push(`ต.${result.crowdTambon}`);
                if (result.crowdUnit) levels.push(`${result.crowdUnit}`);
        
                const label = levels.join(' » ');
                
                if (!groupResultsMap[label]) {
                    let groupResult = {label: label, children: [result]}
                    groupResultsMap[label] = groupResult
                    groupResults.push(groupResult)
                } else {
                    groupResultsMap[label].children.push(result)
                }
            })

            this.setState({
                results: groupResults
            })

        } else {
            this.setState({
                results: null
            })
        }


    }


    renderAlbum = (groupResult, i) => {

        return (
            <div key={i} className={`photo-zone-unit`}>
                {/* <div className={`photo-unit _fw-bd _fs-16 _fs-22-sm`}>{groupResult.label}</div> */}
                <div className={`photo-set d-flex flex-row flex-wrap justify-content-start`}>
                    {groupResult.children.map((result, k) => (
                        result.files.map((file, j) =>
                            <Link key={`${i}-${k}-${j}`}
                                  to={`/crowdsource/digitize/${result.id}?from=${window.location.pathname}${window.location.search}`}
                                  className={`photo-item`}
                                  style={{backgroundImage: 'url("' + file.url + '")'}}>
                            </Link>
                        )
                    ))}
                    
                </div>
                <br />
            </div>
        )
    }

    render() {
        

        const { province, zone, amphoe, tambon, unit } = this.state

        let progress = this.state.unitResult

        if (!progress) {

            const loader = (
                <div className="report-loading-layer-wrapper text-center" style={{zIndex: '9999'}}>
                    <div className="report-loading-layer" style={{width: '600px', height: '400px', backgroundColor: 'rgba(0,0,0,0.4)'}}>
                        <div className="mx-auto rounded p-4" style={{maxWidth: '200px', width: '100%', backgroundColor: 'rgba(255,255,255,0.8)'}}>
                            <img src="/static/images/preloading.gif" alt="loading ..." style={{width: '100%', height: 'auto', maxWidth: '200px'}}/>
                        </div>
                    </div>
                </div>
            )
            return loader
        }

        let scoreMap = {}

        if (province) {
            progress = progress[province] || {}
        }
        if (zone) {
            progress = progress[zone] || {}
        }
        if (amphoe) {
            progress = progress[amphoe] || {}
        }
        if (tambon) {
            progress = progress[tambon] || {}
        }
        if (unit) {
            progress = progress[unit] || {}
        }

        if (unit) {
            Object.keys(progress).forEach(n => {
                scoreMap[n] = scoreMap[n] || 0
                scoreMap[n] += progress[n]
            })
        } else if (tambon) {
            Object.keys(progress).forEach(u => {
                Object.keys(progress[u]).forEach(n => {
                    scoreMap[n] = scoreMap[n] || 0
                    scoreMap[n] += progress[u][n]
                })
            })
        } else if (amphoe) {
            Object.keys(progress).forEach(t => {
                Object.keys(progress[t]).forEach(u => {
                    Object.keys(progress[t][u]).forEach(n => {
                        scoreMap[n] = scoreMap[n] || 0
                        scoreMap[n] += progress[t][u][n]
                    })
                })
            })
        } else if (zone) {
            Object.keys(progress).forEach(a => {
                Object.keys(progress[a]).forEach(t => {
                    Object.keys(progress[a][t]).forEach(u => {
                        Object.keys(progress[a][t][u]).forEach(n => {
                            scoreMap[n] = scoreMap[n] || 0
                            scoreMap[n] += progress[a][t][u][n]
                        })
                    })
                })
            })
            
        } else if (province) {
            Object.keys(progress).forEach(z => {
                Object.keys(progress[z]).forEach(a => {
                    Object.keys(progress[z][a]).forEach(t => {
                        Object.keys(progress[z][a][t]).forEach(u => {
                            Object.keys(progress[z][a][t][u]).forEach(n => {
                                scoreMap[n] = scoreMap[n] || 0
                                scoreMap[n] += progress[z][a][t][u][n]
                            })
                        })
                    })
                })
            })
            
        } else {
            Object.keys(progress).forEach(p => {
                Object.keys(progress[p]).forEach(z => {
                    Object.keys(progress[p][z]).forEach(a => {
                        Object.keys(progress[p][z][a]).forEach(t => {
                            Object.keys(progress[p][z][a][t]).forEach(u => {
                                Object.keys(progress[p][z][a][t][u]).forEach(n => {
                                    scoreMap[n] = scoreMap[n] || 0
                                    scoreMap[n] += progress[p][z][a][t][u][n]
                                })
                            })
                        })
                    })
                })
            })
        }

        let candidateMap = {}
        this.hub.candidates.forEach(c => {
            candidateMap[c.no] = c 
        })

        // progress = {
        //     party: Object.keys(scoreMap).map(n => ({key: candidateMap[n]? candidateMap[n].party.replace('พรรค', ''): n, total: scoreMap[n]}))
        //     // party: [
        //     //     {
        //     //         key: '',
        //     //         total: 0
        //     //     }
        //     // ]
        // }

        progress = {
            party: this.hub.candidates.map(c => ({key: c.party.replace('พรรค', ''), total: scoreMap[c.no] || 0}))
        }

        return (
            
            <div >
                <div className="visual-wrapper d-flex flex-md-row flex-column justify-content-between">
                    <div className="left pt-sm-5 pt-3">
                        <div className="px-3">
                            <div className="text-center">
                                <h1 className="_lh-1 _fs-30 _fs-sm-42">ผลการนับคะแนนเลือกตั้งซ่อม <br />สมุทรปราการ รายอำเภอ</h1>
                            </div>

                            <div className="row justify-content-center mb-4">
                                <div className="col-12 col-lg-5">
                                    <div className="row justify-content-md-center pt-2 d-none">
                                        <div className="col-12">
                                            <div className="row">

                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>จังหวัด</label>
                                                        <select value={province} className="form-control" onChange={this.onProvinceChange}>
                                                            <option value="" className="text-muted">- เลือกจังหวัด -</option>
                                                            {this.provinces.map(p => {
                                                                return (
                                                                    <option key={p.id} value={p.name}>{p.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>เขตเลือกตั้งที่</label>
                                                        <select value={zone} className="form-control" onChange={this.onZoneChange}>
                                                            <option value="">- เลือกเขต -</option>
                                                            {this.state.zones.map(it => {
                                                                return (
                                                                    <option key={it.name} value={it.name}>{it.name}</option>
                                                                )
                                                            })}
                                                        </select>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    <div className="row justify-content-md-center">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>อำเภอ</label>
                                                        <select value={amphoe} className="form-control" onChange={this.onAmphoeChange}>
                                                            <option value="">- เลือกอำเภอ -</option>
                                                            {this.state.amphoes.map(it => {
                                                                return (
                                                                    <option key={it.name} value={it.name}>{it.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>เทศบาล / ตำบล</label>
                                                        <select value={tambon} className="form-control" onChange={this.onTambonChange}>
                                                            <option value="">- เลือกเทศบาล / ตำบล -</option>
                                                            {this.state.tambons.map(it => {
                                                                return (
                                                                    <option key={it.name} value={it.name}>{it.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row justify-content-md-center">
                                        <div className="col-12">

                                            <div className="form-group">
                                                <label>หน่วยเลือกตั้ง / สถานที่</label>

                                                <select value={unit} className="form-control" onChange={this.onUnitChange}>
                                                    <option value="">- เลือกหน่วยเลือกตั้ง / สถานที่ -</option>
                                                    {this.state.units.map(it => {
                                                        return (
                                                            <option key={it.name} value={it.name}>{it.name}</option>
                                                        )
                                                    })}
                                                    <optgroup label=""></optgroup>
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                    

                                </div>
                            </div>


                            <VisualPartyProgress hub={this.hub} progress={progress} />

                            {this.state.results && this.state.results.length ?
                            <div className="vote-result-bar mx-auto mt-4">
                                <h3>ข้อมูลดิบของหน่วยเลือกตั้งนี้</h3>
                                <div className={`d-flex flex-column flex-wrap justify-content-start mb-3`}>
                                    {this.state.results.map((groupResult, i) => this.renderAlbum(groupResult, i))}
                                </div>
                            </div>
                            :
                            ``
                            }

                        </div>
                    </div>

                    <div className={`right`}>
                        <VisualSidebar />
                    </div>
                    
                </div>
            </div>
        )
    }

}