import React from 'react'
import PageComponent from './PageComponent.jsx'

export default class CrowdSourcingThankYou extends PageComponent {
    constructor(props) {
        super(props)

        this.pageCode = 'crowdsource-thankyou';
        this.hub = props.hub;
    }

    elog = (code, value) => {
        this.hub.logStat(this.pageCode, code)
    }

    gotoRandomPage = () => {

        setTimeout(() => this.elog('click-fill-other-result', {
            province_name: this.state.province,
            zone: this.state.zone,
            unit: this.state.unit,
            total: v
        }), 0);

        this.props.history.push('/crowdsource/digitize')
    }

    gotoHomePage = () => {

        setTimeout(() => this.elog('click-return-homepage', {
            province_name: this.state.province,
            zone: this.state.zone,
            unit: this.state.unit,
            total: v
        }), 0);

        this.props.history.push('/crowdsource')
    }

    render = () => {
        return (
            <div className="wrapper-height100 d-flex justify-content-center align-items-center">
                <div className="thank-you-page text-center">
                    <div className="container">
                        <i className="fa fa-check-circle fa-lg py-3"></i>
                        <h2>ได้รับข้อมูลเรียบร้อย</h2>
                        <h3>ขอบคุณที่ช่วยรายงานผลเลือกตั้ง</h3>

                        <div>
                            <button className="btn btn-nc btn-lg btn-dark" onClick={this.gotoRandomPage}>กรอกตัวเลขจากภาพอื่นๆ</button>
                        </div>

                        <div>
                            <button className="btn btn-nc btn-md mt-5" onClick={this.gotoHomePage}>กลับหน้าหลัก</button>
                        </div>
                    </div>

                </div>
            </div>
            
        )
    }
}