import React from "react";

import Party from "../data/party.json";

import {Link} from "react-router-dom";


const popularSort = (a, b) => {
    return (a.total > b.total) ? -1 : ((b.total > a.total) ? 1 : 0)
}

function numberWithCommas(x) {
    return (x || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}



export default class VisualPartyProgress extends React.Component {

    constructor(props) {
        super(props);

        this.hub = props.hub;
        
    }


    resultProgressBarItem(candidate, progress, totalPeople) {

        // if (!party) {
        //     party = {
        //         shortName: '...',
        //         color: null,
        //         allTotal: '...',
        //         zoneTotal: '...',
        //         finalMoreTotal: '...'
        //     }
        // }

        // let candidate = this.candidateMap[`พรรค${party.key}`]
        let partyMap = {}
        progress.party.forEach(party => {
            partyMap[party.key] = party
        })

        let party = partyMap[candidate.party.replace('พรรค', '')] || {key: candidate.party.replace('พรรค', ''), total: 0}
        let percent = totalPeople ? party.total/totalPeople*100: 0

        return (
            <div key={party.key} className={`item text-white position-relative`}>
                <div className={`progress rounded-0 ${Party[candidate.party.replace('พรรค', '')].available ? '' : 'disabled' }`}>
                    <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, backgroundColor: Party[party.key].color}} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100"></div>
                    <div className="progress-detail d-flex flex-row align-items-center justify-content-between w-100 py-3 px-3">
                        <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center w-100">
                            <div className="candidate-party position-relative mr-sm-3 mr-2 rounded-circle text-center" style={{ width: '70px' }}>
                                <img className="img-fluid" src={`/static/images/icon-party/${candidate.party}.png`} srcSet={`/static/images/icon-party/${candidate.party}.png 1x, /static/images/icon-party/${candidate.party}@2x.png 2x`} />
                            </div>
                            <div className="candidate-desc d-flex flex-row align-items-center">
                                <div className="number rounded text-center text-white _fw-med" style={{ backgroundColor: 'transparent', border: '2px solid white'}}>{candidate.no}</div>
                                <div className="info _fw-med">
                                    <div className="mb-0 _fs-24">{candidate.prefix} {candidate.firstname} {candidate.lastname}</div>
                                    <div className="mb-0 _fs-16">{Party[candidate.party.replace('พรรค', '')].available ? candidate.party : '(ขาดคุณสมบัติ)' }</div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="score text-right">
                            <div className="_fs-42 _lh-80 _fw-bd">{numberWithCommas(party.total)}</div> 
                            <div className="_fs-16 _fw-med">คะแนน</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {

        let totalPeople = 0
        let progress = this.props.progress

        if (!progress || !progress.party) {
            return ''
        }
        // let totalPeople = 132397
        progress.party.forEach(party => {
            totalPeople += party.total
        })

        return (
            
            <div className="vote-result-bar mx-auto">
                {this.hub.candidates.map(candidate => this.resultProgressBarItem(candidate, progress, totalPeople))}
            </div>

        )
    }

}