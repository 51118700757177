import React from 'react'
import PageComponent from './PageComponent.jsx'
import Link from 'react-router-dom/es/Link'

export default class CrowdSourcing extends PageComponent {
    constructor(props) {
        super(props)
        this.hub = props.hub

        this.pageCode = 'crowdsource'
        this.state = {}
    }

    componentDidMount() {
        this.hub.pushPath(this.pageCode)
    }

    chooseSection = () => {
        const elog = code => {
            this.hub.logStat(this.pageCode, code)
        }
        return (
            <div className="crowdsourcing-wrapper wrapper-height100">
                {/* <div className="container"> */}
                    <div className="row justify-content-md-center">
                        <div className="col-12 col-lg-6">
                            <div className="card py-5 px-4">
                                <h2>มาช่วยกัน<br />รายงานผลเลือกตั้ง</h2>
                                <p className="lead">ถ่ายรูปกระดานนับคะแนนเลือกตั้งที่นับเสร็จแล้ว จากหน่วยเลือกตั้งใกล้บ้าน <i>หรือ</i> ช่วยกรอกตัวเลขจากรูปภาพผลคะแนนที่มีคนส่งเข้ามา</p>

                                <h3 className="mt-5">มาลองกันเลย!</h3>
                                <div className="row">
                                    <div className="col-6">
                                        <Link to="/crowdsource/upload" onClick={() => elog('click-upload-result-image')} className="text-white d-block border rounded text-center px-3 py-2 bg-dark h-100">
                                            <img src="/static/images/ic-report@2x.png" className="img-fluid" alt="อัปโหลดภาพ" /><br />
                                            อัปโหลดภาพ
                                        </Link>
                                    </div>
                                    <div className="col-6">
                                        <Link to="/crowdsource/digitize" onClick={() => elog('click-fill-result')} className="text-white d-block border rounded text-center px-3 py-2 bg-dark h-100">
                                            <img src="/static/images/ic-send-result@2x.png" className="img-fluid" alt="กรอกคะแนน" /><br />
                                            กรอกคะแนน
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                                            
                    {/* </div> */}
                    
                </div>
            </div>
            
        )
    }

    render = () => {
        return (
            <div className="container crowdsourcing -landing">
                {this.chooseSection()}
            </div>
        )
    }
}