import React from 'react'
import PageComponent from './PageComponent.jsx'
import Autosuggest from 'react-autosuggest';
import Redirect from "react-router-dom/es/Redirect";
import Link from 'react-router-dom/es/Link';

export default class CrowdSourcingZoneDetail extends PageComponent {
    constructor(props) {
        super(props)

        this.pageCode = 'crowdsource-zone-detail';
        this.hub = props.hub;

        this.lockProvince = 'สมุทรปราการ'
        this.lockZone = '5'


        let {province, zone, location} = props.match.params;

        if (location) {
            let matches = [];
            this.searchLocation([`id-${location}`], this.hub.zones, matches, 1);
            location = matches[0] || null;
        }

        this.state = {

            redirect: false,

            province: province || this.lockProvince,
            zone: zone || this.lockZone,
            results: [],
            loading: true,

            // Location
            location: location,
            queryLocation: this.getLocationSuggestionValue(location),
            locationMatches: []
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.hub.pushPath(this.pageCode);
        let value = false;
        if (this.state.province && this.state.zone) {
            value = {province: this.state.province, zone: this.state.zone};
        }
        this.hub.logStat(this.pageCode, 'crowdsource-zone-detail-page', value);
        this.updateLocation();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.redirect) {
            this.state.redirect = false;
        }
    }

    // ===================================================
    // Location
    // ===================================================

    searchLocation = (keywords, items, matches, limit, parents, root) => {

        var self = this;

        items.forEach(item => {

            if (matches.length >= limit) {
                return false;
            }

            let label = item.postcode ? item.name + ':' + item.postcode: item.name;
            label = item.zone ? label + ':' + item.zone + '$': label;
            // label = item.zone? label + ':เขต ' + item.zone: label;
            label = parents ? parents + '|' + label : label;

            let province = root? root: item.name;

            let isMatch = !keywords || keywords.filter(keyword => {
                if (parseInt(keyword) && !parseInt(keywords.join())) { // detect zone
                    return label.indexOf(':' + keyword + '$') !== -1
                } else if (keyword == 'เขต') {
                    return true;
                } else if (keyword.indexOf('id-') === 0 && String(item.id) === keyword.replace('id-', '')) {
                    return true;
                } else {
                    return label.indexOf(keyword) !== -1
                }
            }).length >= keywords.length;

            if (item.children) {
                self.searchLocation(keywords, item.children, matches, limit, label, province);
            } else if (isMatch) {
                matches.push({
                    label: label,
                    id: item.id,
                    province: province,
                    zone: item.zone
                });
            }

        });
    }

    getLocationSuggestions = (value) => {
        const keywords = value.toLowerCase().replace(/ »/g, '').trim().split(' ');
        let matches = [];
        if (value.length >= 2) {
            this.searchLocation(keywords, this.hub.zones, matches, 10);
        }
        return matches;
    }


    onLocationChange = (event, { newValue }) => {
        this.setState({
            location: null,
            queryLocation: newValue
        });
    }

    onLocationSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            location: null,
            locationMatches: this.getLocationSuggestions(value)
        });
    }

    onLocationSuggestionsClearRequested = () => {
        this.setState({
            locationMatches: []
        });
    }

    onLocationSelected = (location) => {
        if (location) {
            this.hub.logStat(this.pageCode, 'crowdsource-zone-detail-on-location-select', location);
            this.setState({
                redirect: `/crowdsource/search/${location.province}/${location.zone}/${location.id}`
            });
        }
    }

    onLocationSuggestionSelected = (event, { suggestion }) => {
        this.onLocationSelected(suggestion);
    }

    onLocationClear = (event) => {
        this.refs.locationAutosuggest.props.inputProps.value = '';
        this.refs.locationAutosuggest.input.value = '';
        this.refs.locationAutosuggest.input.focus();

        let value = '';
        this.setState({
            location: null,
            queryLocation: value,
            locationMatches: this.getLocationSuggestions(value)
        });
    }

    getLocationSuggestionValue = (item) => {
        if (!item) return '';
        const location = item.label.split('|').map((level, i) => {
            let levelLabel = level.split(':')[0]
            
            if (i == 1) {
                levelLabel = `เขต ${levelLabel}`
            }
            return levelLabel
        })
        return `${location.join(' » ')}`;
    }

    shouldRenderLocationSuggestions = (value) => {
        return true;
    }

    renderLocationItem = (item) => {
        return this.getLocationSuggestionValue(item);
    }


    getMergeLocation = () => ({province: this.lockProvince, zone: this.lockZone})

    updateLocation = async () => {

        this.setState({
            loading: true
        });

        let mLocation = this.getMergeLocation();


        if (false && !mLocation.province && !mLocation.zone) { // search page

        } else if (false && !mLocation.zone) { // province page

        } else { // zone page

            console.log(mLocation.province, mLocation.zone)

            let results = await this.hub.db.collection(`${this.hub.appMode}--vote-results`)
                .where('parentId', '==', '')
                .where('hasFiles', '==', true)
                // .where('markAsAbuse', '==', false)
                .where('crowdProvince', '==', mLocation.province)
                .where('crowdZone', '==', `${mLocation.zone}`)
                .orderBy('crowdAmphoe', 'asc')
                .orderBy('crowdTambon', 'asc')
                .orderBy('crowdUnit', 'asc')
                .orderBy('created', 'asc')
                .get().then(qs => {
                    let r = [];
                    qs.forEach(item => {
                        r.push({id: item.id, ...item.data()});
                    });
                    return r;
                });

            let groupResults = []
            let  groupResultsMap = {}

            results.forEach(result => {
                let levels = []
                if (result.crowdAmphoe) levels.push(`${result.crowdAmphoe}`);
                if (result.crowdTambon) levels.push(`${result.crowdTambon}`);
                if (result.crowdUnit) levels.push(`${result.crowdUnit}`);
        
                const label = levels.join(' » ');
                
                if (!groupResultsMap[label]) {
                    let groupResult = {label: label, children: [result]}
                    groupResultsMap[label] = groupResult
                    groupResults.push(groupResult)
                } else {
                    groupResultsMap[label].children.push(result)
                }
            })

            this.setState({
                results: groupResults,
                loading: false

            });

            console.log('loading', 'false')
        }

    }

    getThumb = (url) => {
        const dirName = 'uploads%2Fvote-results%2F';

        if (url.indexOf('futurista') !== -1) {
            url = `https://images.weserv.nl/?url=${url}&w=256&h=256&t=square&a=center`;
        } else {
            url = url.replace(dirName, `${dirName}thumb@256_`);
        }
        return url;
    }

    renderAlbum = (groupResult, i) => {

        return (
            <div key={i} className={`photo-zone-unit`}>
                <div className={`photo-unit _fw-bd _fs-16 _fs-22-sm`}>{groupResult.label}</div>
                <div className={`photo-set d-flex flex-row flex-wrap justify-content-start`}>
                    {groupResult.children.map((result, k) => (
                        result.files.map((file, j) =>
                            <Link key={`${i}-${k}-${j}`}
                                  to={`/crowdsource/digitize/${result.id}?from=${window.location.pathname}`}
                                  className={`photo-item`}
                                  style={{backgroundImage: 'url("' + file.url + '")'}}>
                            </Link>
                        )
                    ))}
                    
                </div>
                <br />
            </div>
        )
        // return result.files.map((file, j) =>
        //     <Link key={`${i}-${j}`}
        //             to={`/crowdsource/digitize/${result.id}?from=${window.location.pathname}`}
        //             className={`photo-item`}
        //             style={{backgroundImage: 'url("' + this.getThumb(file.url) + '")'}}>
        //     </Link>
        // )
    }

    renderPageContent = () => {


        const loader = (
            <div className="report-loading-layer-wrapper text-center" style={{zIndex: '9999'}}>
                <div className="report-loading-layer" style={{width: '600px', height: '400px', backgroundColor: 'rgba(0,0,0,0.4)'}}>
                    <div className="mx-auto rounded p-4" style={{maxWidth: '200px', width: '100%', backgroundColor: 'rgba(255,255,255,0.8)'}}>
                        <img src="/static/images/preloading.gif" alt="loading ..." style={{width: '100%', height: 'auto', maxWidth: '200px'}}/>
                    </div>
                </div>
            </div>
        )

        let mLocation = this.getMergeLocation();


        if (false && !mLocation.province && !mLocation.zone) { // search page
            // TODO: make it this or nothing do?
        } else if (false && !mLocation.zone) { // province page
            // TODO: make it this
        } else { // zone page
            return (
                <div className={`container-fluid py-3 flex-grow-1 bg-crowdsourcing`}>
                    <div className={`container`}>
                        <div className={`p-3 bg-white shadow-1dp`}>
                            <div className={`d-flex flex-column`}>
                                <h3 className={`_fw-med _fs-36 _lh-80 mb-0`}>
                                    {this.state.province} เขต {this.state.zone}
                                </h3>
                                <div className={`_fw-med _fs-20 mb-3`}>
                                    {/* {this.hub.utils.getDeepLocation(this.state.province, this.state.zone)} */}
                                </div>
                                <div className={`_fw-med _fs-18 mb-2 d-none`}>หน่วยเลือกตั้ง (น.)</div>
                            </div>
                            <div className={`d-flex flex-column flex-wrap justify-content-start mb-3`}>
                                {this.state.loading ? loader : this.state.results.length ?
                                    this.state.results.map((groupResult, i) => this.renderAlbum(groupResult, i))
                                    :
                                    <div className={`mt-5`}><h3>ยังไม่มีข้อมูลดิบที่เขตนี้</h3></div>
                                }
                            </div>
                        </div>

                        <div className={`crowd-action-wrapper mt-4 mx-auto text-center`}>
                            <div className={``}>
                                <Link className={`btn btn-secondary bg-black _fw-med _fs-16 mx-1`} to={`/crowdsource/digitize`}><img src="/static/images/ic-send-result.png" srcSet="/static/images/ic-send-result.png 1x, /static/images/ic-send-result@2x.png 2x" width="30px" /> กรอกคะแนน</Link>
                                <Link className={`btn btn-secondary bg-black _fw-med _fs-16 mx-1`} to={`/crowdsource/upload`}><img src="/static/images/ic-report.png" srcSet="/static/images/ic-report.png 1x, /static/images/ic-report@2x.png 2x" width="30px" /> อัพโหลดภาพ</Link>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

    }

    render = () => {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }


        const {
            queryLocation, locationMatches
        } = this.state;

        const locationInputProps = {
            id: 'input-location',
            placeholder: 'อำเภอ / ตำบล / หน่วยเลือกตั้ง / สถานที่',
            className: 'form-control _fw-med',
            value: queryLocation,
            onChange: this.onLocationChange
        };


        let locationButton;
        if (queryLocation) {
            locationButton = (
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button">
                        <Link to={`/crowdsource/search`}><i className={`fa fa-close d-flex align-items-center`}> </i></Link>
                    </button>
                </div>
            );
        } else {
            locationButton = (
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button"><img src="/static/images/ic-search.png" srcSet="/static/images/ic-search.png 1x, /static/images/ic-search@2x.png 2x" width="18px"/></button>
                </div>
            );
        }

        return (
            <div className={`bg-crowdsourcing content d-flex flex-column flex-grow-1`}>
                <div className={`container-fluid py-4 bg-efefef`}>
                    <h2 className="_fw-med _fs-26 mb-3 text-center">ดูข้อมูลดิบของแต่ละหน่วย</h2>
                    {/* <div className={`search-switch-wrapper mx-auto d-flex flex-row justify-content-between align-items-center`}>
                        <div className={`search-box-wrapper -zone-detail text-center d-flex flex-row align-items-center`}>
                            <div className={`input-group`}>
                                <Autosuggest
                                    ref="locationAutosuggest"
                                    suggestions={locationMatches}
                                    onSuggestionsFetchRequested={this.onLocationSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onLocationSuggestionsClearRequested}
                                    onSuggestionSelected={this.onLocationSuggestionSelected}
                                    getSuggestionValue={this.getLocationSuggestionValue}
                                    shouldRenderSuggestions={this.shouldRenderLocationSuggestions}
                                    renderSuggestion={this.renderLocationItem}
                                    inputProps={locationInputProps}
                                />
                                {locationButton}
                            </div>
                        </div>
                    </div> */}
                </div>
                {this.renderPageContent()}
            </div>
        )
    }
}