import React from "react";
import PageComponent from "./PageComponent.jsx";

import {Pie} from 'react-chartjs-2';
import randomColor from 'randomcolor';

const popularSort = (a,b) => (a.total > b.total) ? -1 : ((b.total > a.total) ? 1 : 0);

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// const RD3Component = rd3.Component;

export default class Overview extends PageComponent {

    constructor(props) {
        super(props);
        this.hub = props.hub;

        this.state = {
            loading: true,
            labels: [],
            data: [],
            ageRange: '',
            event: 'candidate-detail-page',
            counting: 'total',
            displayRawTable: false,
            total: '...',
            uniqueTotal: '...',
            graphData: {},
            rawData: [],

            d3Map: ''
        };
        this.collection = this.hub.db.collection(`production--aggregate`);

        this.graphOptions = {
            // responsive: false,
            animation: false,
            legend: {
                display: false,
                position: 'right',
                labels: {
                    boxWidth: 12,
                }
            }
        };

        this.updateDataSource = this.updateDataSource.bind(this);
        this.onAgeRangeChange = this.onAgeRangeChange.bind(this);
        this.onEventChange = this.onEventChange.bind(this);
        this.onCountingChange = this.onCountingChange.bind(this);
        this.showRawTable = this.showRawTable.bind(this);
        this.renderD3Map = this.renderD3Map.bind(this);

        this.updateDataSource();

    }

    componentDidMount() {
        // this.setState({d3Map: d3MapElm});
    }

    updateDataSource() {

        let self = this;

        let aggDoc = this.collection.doc(this.state.event);
        if (this.state.ageRange) {
            aggDoc = aggDoc.collection('age-ranges').doc(this.state.ageRange);
        }

        aggDoc.get().then(doc => {
            const aggData = doc.data();

            console.log('aggData', aggData);

            let labels = [];
            let data = [];
            let rawData = [];

            let popular = aggData.popular || [];
            popular.sort(popularSort);


            let totalPopular = popular.reduce(function (a, b) {
                let total = b[self.state.counting];
                return total == null ? a : a + total;
            }, 0);

            popular.forEach(item => {
                let total = item[self.state.counting];

                let percent = total/totalPopular*10000/100;
                percent = percent.toFixed(2);

                let party = item.key.replace('พรรค', '');

                let label = percent + '% ' + party;
                data.push(total);
                labels.push(label);
                rawData.push({
                    party: party,
                    percent: percent + '%',
                    total: total
                })
            });



            const graphData = {
                datasets: [{
                    label: "Popular Vote",
                    backgroundColor: randomColor({count: data.length }),
                    borderWidth: 0,
                    data: data,

                }],
                labels: labels
            };


            // ========================
            // Map
            // ========================


            this.setState({
                loading: false,
                data: data,
                labels: labels,
                total: aggData.total,
                uniqueTotal: aggData.uniqueTotal,
                graphData: graphData,
                rawData: rawData
            })

        });
    }

    onAgeRangeChange(event) {
        let value = event.target.value;
        this.setState({
            ageRange: value,
            loading: true,
            total: '...',
            uniqueTotal: '...'
        }, this.updateDataSource);
    }

    onEventChange(event) {
        let value = event.target.value;
        this.setState({
            event: value,
            loading: true,
            total: '...',
            uniqueTotal: '...'
        }, this.updateDataSource);
    }

    onCountingChange(event) {
        let value = event.target.value;
        this.setState({
            counting: value,
            loading: true,
            total: '...',
            uniqueTotal: '...'
        }, this.updateDataSource);
    }

    showRawTable(event) {
        this.setState({displayRawTable: true})
    }

    renderRawTable() {
        if (!this.state.displayRawTable) {
            return '';
        }

        return (
            <div className="raw-table">
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th className="text-right">ลำดับ</th>
                        <th>ชื่อพรรค</th>
                        <th className="text-right">คะแนน</th>
                        <th className="text-right">สัดส่วน</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.rawData.map((item, i) => {
                        return (
                            <tr key={`raw-table-row-${i}`}>
                                <td className="text-right">{i+1}</td>
                                <td>{item.party}</td>
                                <td className="text-right">{numberWithCommas(item.total)}</td>
                                <td className="text-right">{item.percent}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }

    renderD3Map() {
        console.log('this.state.d3Map', this.state.d3Map);
        return (
            <div>
                <RD3Component data={this.state.d3Map} />
            </div>
        )
    }

    renderContent() {


        // if (this.state.loading) {
        //     return (
        //         <div className="text-center text-dark">
        //             <div className="lds-grid">
        //                 <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        //             </div>
        //         </div>
        //     )
        // }
        //
        // if (this.state.data.length === 0) {
        //     return (
        //         <h3 className="text-center mt-5">ไม่มีข้อมูลของตัวเลือกที่คุณเลือก</h3>
        //     )
        // }

        return (
            <div>
                <div className="graph-summary text-center mt-3 mb-5">
                    <small>
                        จำนวนที่เข้าใช้ <strong>{numberWithCommas(this.state.total)}</strong> ครั้ง
                        จากผู้ใช้งาน <strong>{numberWithCommas(this.state.uniqueTotal)}</strong> คน ตามเงื่อนไขข้างต้น
                    </small>
                </div>
                <Pie
                    data={this.state.graphData}
                    options={this.graphOptions}
                />
                <div>
                    <div className="text-center mb-5 mt-2">
                        <button className="btn btn-sm btn-dark" onClick={this.showRawTable}>
                            ดูตารางคะแนน
                        </button>
                    </div>
                    {this.renderRawTable()}
                </div>

                <div>
                    {/*{this.renderD3Map()}*/}
                </div>
            </div>
        )
    }


    render() {

        return (
            <div className="container">
                <h1 className="text-center mt-4 mb-4">สถิติการใช้งาน</h1>
                <div className="graph-content">
                    <div className="graph-form row">

                        <div className="col-12 col-lg-4">
                            <div className="form-group input-age-range-wrapper">
                                <label htmlFor="input-age-range">ดูตามอายุ</label>
                                <select className="form-control" id="input-age-range" value={this.state.ageRange} onChange={this.onAgeRangeChange}>
                                    <option>- ทุกช่วง -</option>
                                    <option value="18 - 24">18 - 24 ปี</option>
                                    <option value="25 - 34">25 - 34 ปี</option>
                                    <option value="35 - 44">35 - 44 ปี</option>
                                    <option value="45 - 60">45 - 60 ปี</option>
                                    <option value="60+">60 ปีขึ้นไป</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4">
                            <div className="form-group input-event-wrapper">
                                <label htmlFor="input-event">ดูตามเหตุการณ์</label>
                                <select className="form-control" id="input-event" value={this.state.event} onChange={this.onEventChange}>
                                    <option value="candidate-detail-page">เข้าสู่หน้าผู้สมัคร</option>
                                    <option value="candidate-save">บันทึกหน้าผู้สมัคร</option>
                                    <option value="candidate-print">พิมพ์หน้าผู้สมัคร</option>
                                    <option value="candidate-send">ส่งต่อหน้าผู้สมัคร ทุกช่องทาง</option>
                                    <option value="candidate-share-line">ส่งต่อหน้าผู้สมัคร ทางไลน์</option>
                                    <option value="candidate-share-facebook">ส่งต่อหน้าผู้สมัคร ทางเฟซบุ๊ค</option>
                                    <option value="candidate-share-twitter">ส่งต่อหน้าผู้สมัคร ทางทวิทเตอร์</option>
                                    <option value="candidate-share-email">ส่งต่อหน้าผู้สมัคร ทางอีเมล</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4">
                            <div className="form-group input-counting-wrapper">
                                <label htmlFor="input-counting">นับจาก</label>
                                <select className="form-control" id="input-event" value={this.state.counting} onChange={this.onCountingChange}>
                                    <option value="total">จำนวนครั้ง</option>
                                    <option value="uniqueTotal">จำนวนผู้ใช้</option>
                                </select>
                            </div>
                        </div>

                    </div>

                    <div className="graph-graph mt-4">
                        {this.renderContent()}
                    </div>
                </div>
            </div>
        )
    }
}