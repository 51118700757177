import React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth';

import PageComponent from '../PageComponent.jsx'

export default class AdminCrowdSourcing extends PageComponent {
    constructor (props) {
        super(props)
        this.hub = props.hub

        this.state = {
            pictures: [],
            loaded: false,
            fetching: false,
            error: false,
            hasNext: true,
            loggedIn: firebase.auth().currentUser ? true : false,
            abuse: ''
        }

        this.pageDocIds = []
        this.limit = 50
        this.lastSubmission = null
    }

    componentDidMount = () => {
        this.handleAuthStateChanged()
    }

    handleAuthStateChanged = () => {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.setState({loggedIn: true})
                this.fetch()
            } else {
                this.setState({loggedIn: false, pictures: []})
            }
        })
    }

    fetchNext = async () => {
        const startId = this.lastSubmission.id
        return this.fetch(startId)
    }

    fetchPrevious = async () => {
        this.pageDocIds.pop()
        this.pageDocIds.pop()
        const startId = this.pageDocIds[this.pageDocIds.length - 1]
        return this.fetch(startId)
    }

    fetch = async (startId) => {
        if (this.state.fetching) {
            console.log('Request abort. There is request is working on.')
            return
        }

        this.setState({loaded: false, error: false, fetching: true, pictures: []})

        let callable = await this.hub.functions.httpsCallable('getSubmissions')
        try {
            const params = {
                env: this.hub.appMode,
                limit: this.limit,
                abuse: this.state.abuse,
                startId
            }
            let result = await callable(params)

            const pictures = []
            result.data.submissions.forEach(submission => {
                submission.files.forEach(file => {
                    pictures.push({...file, submission, submitting: false})
                })
            })

            if (result.data.submissions.length > 0) {
                this.lastSubmission = result.data.submissions[result.data.submissions.length - 1]
                this.pageDocIds.push(this.lastSubmission.id)
            }

            this.setState({
                pictures: pictures,
                loaded: true,
                fetching: false,
                hasNext: result.data.submissions.length >= this.limit
            })
        } catch (e) {
            console.error(e)
            this.setState({submissions:[], loaded: true, error: true, fetching: false})
        }
    }

    updateAbuse = async (submission, markAsAbuse) => {
        let callable = await this.hub.functions.httpsCallable('updateAbuse')
        try {
            await callable({
                env: this.hub.appMode,
                submissionId: submission.id,
                markAsAbuse
            })

            this.updateMarkAsAbuseButtonState(submission, false, markAsAbuse)
            this.setState({ pictures: this.state.pictures })
        } catch (e) {
            console.error(e)
            alert(`Failed update abuse on ${submission.id}: ${e}`)
            this.updateMarkAsAbuseButtonState(submission, false, submission.markAsAbuse)
        }
    }

    updateMarkAsAbuseButtonState = (submission, submitting, markAsAbuse) => {
        submission.files.forEach(file => {
            const index = this.state.pictures.findIndex(item => item.id === file.id)
            this.state.pictures[index].submitting = submitting
            this.state.pictures[index].submission.markAsAbuse = markAsAbuse
        })
        this.setState({ pictures: this.state.pictures })
    }

    toggleMarkAsAbuse = (picture) => {
        const markAsAbuse = !picture.submission.markAsAbuse
        this.updateMarkAsAbuseButtonState(picture.submission, !picture.submitting, markAsAbuse)
        this.updateAbuse(picture.submission, markAsAbuse)
    }

    markAsAbuseButton = (picture) => {
        let buttonText = picture.submission.markAsAbuse ? 'Mark as not abuse' : 'Mark as abuse'
        let className = picture.submission.markAsAbuse ? 'btn-primary' : 'btn-danger'
        if (picture.submitting) {
            buttonText = 'Submitting...'
        }
        return (
            <button className={`btn-block ${className}`}
                    disabled={picture.submitting}
                    onClick={() => this.toggleMarkAsAbuse(picture)}>{buttonText}</button>
        )
    }

    submissionItemPicture = (picture) => {
        return (
            <div key={[picture.submission.id, picture.id]} className="col-md-3 col-sm-4">
                <div className="submission-picture-item">
                    <img src={picture.url} className="img-thumbnail rounded" />
                    {this.markAsAbuseButton(picture)}
                </div>
            </div>
        )
    }

    handleFilterAbuseChange = (event) => {
        this.setState({abuse: event.target.value})
        this.pageDocIds = []
        this.fetch()
    }

    submissionItems = () => {
        if (firebase.auth().currentUser) {
            return (
                <div className="submission-wrapper">
                    <div className="row">
                        <div className="col-sm-12">
                            <select value={this.state.abuse} onChange={this.handleFilterAbuseChange}>
                                <option value="">All</option>
                                <option value="1">Abuse</option>
                                <option value="0">Not abuse</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.pictures.map(picture => this.submissionItemPicture(picture))}
                    </div>
                    {this.pagination()}
                </div>
            )
        }
    }

    pagination = () => {
        if (!this.state.fetching) {
            return (
                <nav aria-label="Pagination">
                    <ul className="pagination justify-content-center">
                        {this.previousPageButton()}
                        {this.nextPageButton()}
                    </ul>
                </nav>
            )
        }
    }

    previousPageButton = () => {
        if (this.pageDocIds.length > 1) {
            return (
                <li className="page-item">
                    <a className="page-link" onClick={() => this.fetchPrevious()}>Previous</a>
                </li>
            )
        }
    }

    nextPageButton = () => {
        if (this.state.hasNext) {
            return (
                <li className="page-item">
                    <a className="page-link" onClick={() => this.fetchNext()}>Next</a>
                </li>
            )
        }
    }

    handleEmailChange = (event) => {
        this.setState({email: event.target.value})
    }

    handlePasswordChange = (event) => {
        this.setState({password: event.target.value})
    }

    login = (event) => {
        event.preventDefault();

        firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).catch(error => {
            console.error(error)
        });
    }

    logout = () => {
        firebase.auth().signOut().catch(error => {
            console.error(error)
        })
    }

    loginForm = () => {
        if (!firebase.auth().currentUser) {
            return (
                <form onSubmit={this.login}>
                    <div className="form-group">
                        <label htmlFor="id-email">Email address</label>
                        <input type="email" onChange={this.handleEmailChange} className="form-control" id="id-email"placeholder="Enter email" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="id-password">Password</label>
                        <input type="password" onChange={this.handlePasswordChange} className="form-control" id="id-password" placeholder="Password" />
                    </div>
                    <button type="submit" className="btn btn-primary">Login</button>
                </form>
            )
        }
    }

    logoutButton = () => {
        if (firebase.auth().currentUser) {
            return (
                <button onClick={() => this.logout()}>Logout</button>
            )
        }
    }

    loader = () => {
        if (this.state.fetching) {
            return (
                <div className="justify-content-center">Loading</div>
            )
        }
    }

    render () {
        return (
            <div className="container">
                {this.loginForm()}
                {this.submissionItems()}
                {this.loader()}
                {this.logoutButton()}
            </div>
        )
    }
}
